import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Axios from 'axios';

import { SetListData } from 'interface/Data';

// import dataJson from './list_data.json';

const root = ReactDOM.createRoot(document.getElementById('root'));

// SetListData(dataJson);
// root.render(
//     <React.Fragment>
//         <App />
//     </React.Fragment>
// );

Axios({
    url    : process.env.REACT_APP_API_URL + '/list_data_compact.json',
    mode   : 'no-cors',
    method : 'POST'    
})
.then(response => {
    SetListData(response.data);
    root.render(
        <React.Fragment>
            <App />
        </React.Fragment>
    );
});