import { useState, useEffect } from "react";

import './MesaLiquid.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { SvgArrowFile, SvgClose, SvgHome } from "components/Svg";

export default function MesaLiquid(props){

    const [ page, setPage ]         = useState('index');
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('partsAndServices'));
    const [ showData, setShowData ] = useState(InitialData());

    const [ status, setStatus ]         = useState(false);    
    const [ openIndex, setOpenIndex ]   = useState('');
    const [ openFile, setOpenFile ]     = useState('');
    const [ statusFile, setStatusFile ] = useState(false);
    
    const [ openVideo, setOpenVideo ] = useState(false);
    
    useEffect(()=>{        
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('partsAndServices', setDataPage);
    }, []);

    useEffect(()=>{
        if(status == true){
            document.getElementById('play_1').style.opacity = "0";
            document.getElementById('btn_video_1').style.opacity = "0";
        }
    }, [status]);

    function InitialData(){
        const newData = dataPage.applying.button.find(item => item.id == 243);
        return newData;
    }

    function PlayVideo(id_video, id_div, btn_video) {
        const play_ = document.getElementById(id_video);
        if (play_.paused){
            setOpenVideo(false);
            play_.play();
            document.getElementById(btn_video).style.opacity = "0";
            document.getElementById(id_div).style.opacity = "0";
        }else {
            setOpenVideo(true);
            play_.pause();
            document.getElementById(btn_video).style.opacity = "1";
            document.getElementById(id_div).style.opacity = "1";
        }
    }

    function ClickPage(value){
        setPage(value);
    }

    function TypeClick(){
        switch (page) {
            case 'index':
                return(
                    <div className="show_data_initial">
                        <div className="div_img_liquid">
                            <img alt="img" src="./assets/liquid.png" className="img_liquid" />
                        </div>
                        <div className="div_btn_name">
                            <div className="div_button">
                                <div className="button btn_1">
                                    <div className="show_name button_1" onClick={ ()=>{ SetListPag('idioma', 'pt_br'); setPage('showData'); } }>
                                        { showData.name['pt_br'] }
                                    </div>
                                </div>
                                <div className="button btn_1">
                                    <div className="show_name button_1" onClick={ ()=>{ SetListPag('idioma', 'en'); setPage('showData'); } }>
                                        { showData.name['en'] }
                                    </div>
                                </div>
                                <div className="button btn_1">
                                    <div className="show_name button_1" onClick={ ()=>{ SetListPag('idioma', 'es'); setPage('showData'); } }>
                                        { showData.name['es'] }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
        
            default:

                let showText = '';
                let countDivImg = 0;
                if(showData.data.find(item => item.type == 'text')){
                    showText = showData.data.find(item => item.type == 'text');  
                }

                let countImg = 0;
                if(showData.data.find(item => item.type == 'img')){
                    let showCountImg = showData.data.filter(item => item.type == 'img');
                    countImg = showCountImg[0].img.length;
                }

                let countVideo = 0;
                if(showData.data.find(item => item.type == 'video')){
                    let showCountVideo = showData.data.filter(item => item.type == 'video');
                    countVideo = showCountVideo.length;
                }
                let total = countImg + countVideo;
                return(
                    <>                        
                        <div className="div_topic">
                            <div className="icon_return">
                                <div className="return" onClick={ ()=>{ setPage('index') } }>
                                    <SvgHome className="icon_home" color="#FFDD00" />
                                </div>
                                <div className="name_current_page">
                                    <div className="show_name">/</div>
                                    <div className="show_name">{ showData.name[idioma] }</div>
                                </div>
                            </div>
                        </div>
                        <div className="name_page_click">
                            <div className="show_name_page_click">
                                <div>{ showData.name[idioma] }</div>
                                <div className={ showData.stamp_1 != '' && showData.stamp_2 != '' ? "stamp" : "stamp stamp_one" }>
                                    {
                                        showData.stamp_1 == '' ? null :
                                        <div className="div_stamp">
                                            <img alt="img" src={ showData.stamp_1 } className="stamp_img" />
                                        </div>
                                    }
                                    {
                                        showData.stamp_2 == '' ? null :
                                        <div className="div_stamp">
                                            <img alt="img" src={ showData.stamp_2 } className="stamp_img" />
                                        </div>
                                    }
                                </div>  
                            </div>                          
                        </div>
                        <div className="bg_data">
                            <img alt="img" src="./assets/PartsAndServices/bg_data_agricultural.png" className="bg_data_img" />
                        </div>
                        <div className="div_show_details">
                            <div className="div_show_details_text">
                                <div className="div_show_text" dangerouslySetInnerHTML={ { __html: showText.text[idioma] ? showText.text[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                            </div>
                            <div className="div_show_details_contents">
                                {
                                    showData.data.map((key, index)=>{
                                        switch (key.type) {
                                            case 'video':
                                                return(
                                                    <div className="div_show_details_div_video" key={ index }>
                                                        <div className="play_video" onClick={ ()=>{ setStatus(true); setStatusFile(false); setOpenFile(index); } } style={ { position: "relative" } }>
                                                            <div className="div_show_details_div_video_img_play">
                                                                <img alt="img" src="./assets/PartsAndServices/player.png" className="img_icon" />
                                                            </div> 
                                                            <img alt="img" src={ key.video_img[idioma] } className="div_show_details_div_video_img_" />
                                                        </div>
                                                    </div>
                                                )

                                            case "img":
                                                return(
                                                    <div className={ countVideo == 0 ? 
                                                        total > 2 ? "div_show_details_div_img " : "div_show_details_div_img" : "div_show_details_div_img div_mult_video" } key={ index }>
                                                        {
                                                            key.img.map((key_1, index_1)=>{
                                                                countDivImg = countDivImg + 1
                                                                return(
                                                                    <div className={ 
                                                                        total == 1 ? "div_show_details_show_img show_img_" + countDivImg : 
                                                                        total == 2 ? "div_show_details_show_img two_show_img show_img_" + countDivImg : 
                                                                        countVideo == 0 ? "div_show_details_show_img not_video_mult_show_img show_img_" + countDivImg : 
                                                                        countImg > 2 ? "div_show_details_show_img mult_show_img_video show_img_" + countDivImg : "div_show_details_show_img mult_show_img show_img_" + countDivImg  } key={ index_1 } onClick={ ()=>{ setStatus(false); setStatusFile(true); setOpenFile(index); setOpenIndex(index_1) } }>
                                                                        <div className="search">
                                                                            <img alt="search" src="./assets/search.png" className="icon_search" />
                                                                        </div> 
                                                                        <img alt="img_" src={ key_1[idioma] } className="div_show_details_img" />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                        }
                                    })
                                }
                            </div>
                        </div>
                        {
                            status == false ? null :
                            <div className="div_parts">
                                <div className="list_div_parts">
                                    <div className="close_modal" onClick={ ()=>{ setStatus(false); setStatusFile(false); setOpenFile(''); setOpenIndex(''); } }>
                                        <SvgClose className="icons" color="#FFDD00" />
                                    </div>
                                    <div className="div_show_details_div_video" onClick={ ()=>{ PlayVideo('open_video_1', 'play_1', 'btn_video_1'); } }>
                                        <div id="play_1" className="play_1">
                                            <div id="btn_video_1" className={ openVideo == true ? "btn_video_1 btn_video_1_close" : "btn_video_1" }>
                                                <img alt="img" src="./assets/OperationCenter/player.png" className="img_icon" />
                                            </div> 
                                            <img alt="img" src={ showData.data[openFile].video_img[idioma] } className="img_" />
                                        </div>
                                        <div>
                                            <video id="open_video_1" width="100%" height="100%" controls={ false } loop={ true } autoPlay="autoplay">
                                                <source src={ showData.data[openFile].video[idioma] } type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            statusFile == false ? null :
                            <div className="div_parts">
                                <div className="list_div_parts">
                                    <div className="close_modal" onClick={ ()=>{setStatus(false); setStatusFile(false); setOpenFile(''); setOpenIndex(''); } }>
                                        <SvgClose className="icons" color="#FFDD00" />
                                    </div>
                                    {
                                        (openIndex - 1) >= 0 ? 
                                        <div className="prev_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenIndex(openIndex - 1) } }>
                                            <SvgArrowFile className="arrow_file" color="#FFDD00" />
                                        </div> : null
                                    }
                                    {
                                        (openIndex + 1) < showData.data[openFile].img.length ?
                                        <div className="next_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenIndex(openIndex + 1) } }>
                                            <SvgArrowFile className="arrow_file next_icon" color="#FFDD00" />
                                        </div> : null
                                    }
                                    <div className="show_details">
                                        <div className="mach_div_img">
                                            <img alt="img" src={ showData.data[openFile].img[openIndex][idioma] } className="img_div_parts" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                )
        }
    }

    return(
        <div className="MesaLiquid">
            <div className="reset_page" onClick={ ()=>{ props.ResetPage('index') } } />
            { TypeClick() }
            <div className="div_bg">
                <img alt="img" src="./assets/texture.png" className="texture" />
            </div>   
        </div>
    )
}