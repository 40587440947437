import { useState, useEffect } from "react";

import './PtBr.css';

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function ExpandedPortfolio_PtBr(props){

    const [ nameUrl, setNameUrl ]   = useState(window.location.href.split("#"));
    const [ page, setPage ]         = useState('index');
    const [ dataPage, setDataPage ] = useState(GetDataPage('expanded_portfolio'));

    const [ showData, setShowData ]       = useState(InitialData());
    const [ idPortfolio, setIdPortfolio ] = useState(0);

    useEffect(()=>{
        RegisterDataPage('expanded_portfolio', setDataPage);
    }, []); 

    useEffect(()=>{
        ShowCurrentData();
    }, [page]);

    function InitialData(){
        if(nameUrl[2] == 'grain_harvest'){
            const typeData = dataPage.filter(item => item.origin_ == 'grain_harvest'); 
            const newData  = typeData.filter(item => item.idioma == 'pt_br');
            return newData;

        }else if(nameUrl[2] == 'utility_tractor'){
            const typeData = dataPage.filter(item => item.origin_ == 'utility_tractor'); 
            const newData  = typeData.filter(item => item.idioma == 'pt_br');
            return newData;
            
        }else {
            const typeData = dataPage.filter(item => item.origin_ == 'all'); 
            const newData  = typeData.filter(item => item.idioma == 'pt_br');
            return newData;
        } 
    }

    function PlayVideo(id_video, id) {
        const play_ = document.getElementById(id_video);
        if (play_.paused){
            play_.play();
            document.getElementById(id).style.opacity = "0";
        }else {
            play_.pause();
            document.getElementById(id).style.opacity = "1";
        }
    }

    function ShowCurrentData(){
        const infPage = showData.find(item => item.id == page);
        switch (page) {
            case "index":
                return(
                    <div className="div_show_data">                    
                        <div className="bg_white">
                            {
                                nameUrl[2] == 'utility_tractor' ?
                                <div className="show_data_contents div_logo">   
                                    <img alt="img" src="./assets/ExpandedPortfolio/Imagem4.png" className="logotipo" />
                                </div> : 
                                <div className="show_data_contents div_logo">   
                                    <img alt="img" src="./assets/ExpandedPortfolio/logo.png" className="logotipo" />
                                </div>
                            }
                        </div> 
                        <div className="bg_blue">
                            <div className="show_data_contents">
                                <div className="list_buttons">
                                    {
                                        showData.map((key, index)=>{
                                            if(key.id != 4 && key.id != 18 && key.id != 27){
                                                return(
                                                    <div className="btn_about" key={ index } onClick={ ()=>{ setPage(key.id) } }>
                                                        { key.name }
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                                {
                                    showData.map((key, index)=>{
                                        if(key.id == 4 || key.id == 18 || key.id == 27){
                                            return(
                                                <div className="machine" onClick={ ()=>{ setPage(key.id) } } key={ index }>
                                                    <div className="div_data_machine">
                                                        <div className="title_machine">{ key.name }</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                            <div className="div_example">
                                <img alt="img" src={ "./assets/cursor_pt_br.png" } className="icone_button" />
                            </div>
                        </div>   
                    </div>
                )
            
            // Sobre a marca
            case 1: case 15: case 25:
                return(
                    <div className="page_1">
                        <div className="div_topic">
                            <div className="div_home" onClick={ ()=>{ setPage('index'); setIdPortfolio(0) } }>
                                <img alt="img" src="./assets/home.png" className="img_home" />
                            </div>
                            <div className="div_name_page">
                                <div className="subtitle_page"></div>
                                <div className="name_page">{ infPage.name }</div>
                            </div>
                            <div className="div_logotipo">
                                <img alt="img" src="./assets/ExpandedPortfolio/logo_branco.png" className="logotipo" />
                            </div>
                        </div>
                        <div className="pag_div_rows">
                            <div className="pag_div_img">
                                <img alt="img" src={ infPage.contents[0].img } className="pag_img" />
                            </div>
                            <div className="pag_div_video">
                                <div id="btn_video_pt_br" onClick={ ()=>{ PlayVideo('open_video', 'div_icon_pt_br'); } } className="btn_video_open">
                                    <div id="div_icon_pt_br" className="div_img_play">
                                        <img alt="img" src="./assets/ExpandedPortfolio/player.png" className="img_icon" />
                                    </div>
                                    <video id="open_video" width="100%" height="100%" controls={ false } loop={ true }>
                                        <source src={ infPage.contents[0].video } type="video/mp4" />
                                    </video>
                                </div> 
                            </div>
                        </div>
                        <div className="pag_text" dangerouslySetInnerHTML={ { __html: infPage.contents[0].text != '' ? infPage.contents[0].text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                        <div className="div_example">
                            <img alt="img" src={ "./assets/cursor_pt_br.png" } className="icone_button" />
                        </div>
                    </div>
                )
            // Portfolio
            case 2: case 28: case 17:
                if(idPortfolio == 0){
                    return(
                        <div className="page_2" style={ { gap: 0 } }>
                            <div className="div_topic">
                                <div className="div_home" onClick={ ()=>{ setPage('index'); setIdPortfolio(0) } }>
                                    <img alt="img" src="./assets/home.png" className="img_home" />
                                </div>
                                <div className="div_name_page">
                                    <div className="subtitle_page"></div>
                                    <div className="name_page">{ infPage.name }</div>
                                </div>
                                <div className="div_logotipo">
                                    <img alt="img" src="./assets/ExpandedPortfolio/logo_branco.png" className="logotipo" />
                                </div>
                            </div>
                            {
                                nameUrl[2] == 'grain_harvest' || nameUrl[2] == 'utility_tractor' ? 
                                <div className="list_btn">
                                    {
                                        infPage.contents[0].data_btn.map((key, index)=>{
                                            return(
                                                <div className="show_btn" key={ index } onClick={ ()=>{ setIdPortfolio(key.id) } }>
                                                    { key.name }
                                                </div>
                                            )
                                        })
                                    }
                                </div> :
                                <>           
                                    <div className="div_title">
                                        <div className="pag_title">
                                            { infPage.contents[0].title }
                                        </div>
                                    </div>                             
                                    <div className="name_btn">
                                        <div className="show_img_example">
                                            <img alt="img" src={ infPage.contents[0].img } className="img" />
                                        </div>
                                    </div>
                                    <div className="div_gallery">
                                        <div className="show_img">
                                            <img alt="img" src={ infPage.contents[0].img_bg } className="img" />
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="div_bg_color" />
                        </div>
                    )
                }else {
                    let dataGallery = infPage.contents[0].data_btn.find(item => item.id == idPortfolio);
                    return(
                        <div className="page_2" style={ { gap: 0 } }>
                            <div className="div_topic">
                                <div className="div_home" onClick={ ()=>{ setIdPortfolio(0); } }>
                                    <img alt="img" src="./assets/home.png" className="img_home" />
                                </div>
                                <div className="div_name_page">
                                    <div className="subtitle_page"></div>
                                    <div className="name_page">{ dataGallery.name }</div>
                                </div>
                                <div className="div_logotipo">
                                    <img alt="img" src="./assets/ExpandedPortfolio/logo_branco.png" className="logotipo" />
                                </div>
                            </div>
                            {
                                dataGallery.text == "" ? null : 
                                <div className="data_gallery">
                                    <div className="text_gallery" dangerouslySetInnerHTML={ { __html: dataGallery.text ? dataGallery.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                </div>
                            }
                            <div className="div_gallery">
                                {
                                    dataGallery.gallery.map((key, index)=>{
                                        return(
                                            <div className="show_img" key={ index } style={ { width: dataGallery.gallery.length > 3 ? "330px" : dataGallery.gallery.length == 1 ? "630px" : "430px", paddingTop: dataGallery.text == "" ? "100px": "30px" } }>
                                                <div className="page_div_code">{ key.code }</div>
                                                <div className="page_div_img">
                                                    <img alt="img" src={ key.file } className="img" />
                                                </div>
                                                <div className="page_div_code">{ key.code_original }</div>
                                                {
                                                    key.text == "" ? null : 
                                                    <div className="page_div_text" dangerouslySetInnerHTML={ { __html: key.text ? key.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />  
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="div_bg_color" />
                        </div>
                    )
                }
            // Catálago
            case 3: case 26: case 16:
                return(
                    <div className="page_3">
                        <div className="div_topic">
                            <div className="div_home" onClick={ ()=>{ setPage('index'); setIdPortfolio(0) } }>
                                <img alt="img" src="./assets/home.png" className="img_home" />
                            </div>
                            <div className="div_name_page">
                                <div className="subtitle_page"></div>
                                <div className="name_page">{ infPage.name }</div>
                            </div>
                            <div className="div_logotipo">
                                <img alt="img" src="./assets/ExpandedPortfolio/logo_branco.png" className="logotipo" />
                            </div>
                        </div>
                        <div className="div_data_catalog">
                            <div className="show_bg">
                                <img alt="img" src={ infPage.contents[0].img } className="bg_page" />
                            </div>
                            <div className="div_title">
                                <div className="page_title">{ infPage.contents[0].title }</div>
                                <div className="page_qrcode">
                                    <img alt="img" src={ infPage.contents[0].img_bg } className="img_qrcode" />
                                </div>
                            </div>
                        </div>
                        <div className="div_bg_color" />
                    </div>
                )
            // Valor para concensionáro
            case 4: case 27: case 18:
                return(
                    <div className="page_4">
                        <div className="div_topic">
                            <div className="div_home" onClick={ ()=>{ setPage('index'); setIdPortfolio(0) } }>
                                <img alt="img" src="./assets/home.png" className="img_home" />
                            </div>
                            <div className="div_name_page">
                                <div className="subtitle_page"></div>
                                <div className="name_page">{ infPage.name }</div>
                            </div>
                            <div className="div_logotipo">
                                <img alt="img" src="./assets/ExpandedPortfolio/logo_branco.png" className="logotipo" />
                            </div>
                        </div>
                        <div className="div_data_cash">
                            <div className="show_data_page">
                                <div className="page_title">{ infPage.contents[0].title }</div>
                                <div className="page_text" dangerouslySetInnerHTML={ { __html: infPage.contents[0].text ? infPage.contents[0].text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                
                                <div className="page_title">{ infPage.contents[0].title_1 }</div>
                                <div className="page_text" dangerouslySetInnerHTML={ { __html: infPage.contents[0].text_1 ? infPage.contents[0].text_1.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                            </div>
                            <div className="img_right">
                                <img alt="img" src={ infPage.contents[0].img_bg } className="img_position" />
                            </div>
                        </div>
                    </div>
                )
        }
    }

    return(
        <div className="ExpandedPortfolio_PtBr">
            { ShowCurrentData() }
        </div>
    )
}