import { useState, useEffect } from "react";

import './MesaPartsAndServices.css';

import { GetDataPage } from "interface/Data";

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SvgClose, SvgIdioma } from "components/Svg";

import MesaPartsAndServices_Upgrades from "./Upgrades";
import MesaPartsAndServices_Agricultural from "./Agricultural";
import MesaPartsAndServices_ExpandedPortfolio from "./ExpandedPortfolio";

export default function MesaPartsAndServices(props){

    const [ nameUrl, setNameUrl ]   = useState(window.location.href.split("#"));
    const [ showData, setShowData ] = useState(false);

    const [ page, setPage ]         = useState('index');
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ namePage, setNamePage ] = useState(nameUrl[2]);

    const [ status, setStatus ]             = useState(false);
    const [ statusIdioma, setStatusIdioma ] = useState(false);
    const [ textFixed, setTextFixed ]       = useState({
        "text_1": {
            "pt_br": "Upgrades",
            "en": "Upgrades",
            "es": "Upgrades"       
        },
        "text_2": {
            "pt_br": "Peças e serviços",
            "en": "Parts and service",
            "es": "Partes y Servicio"       
        },
        "text_3": {
            "pt_br": "Portfolio ampliado",
            "en": "Expanded portfolio",
            "es": "Cartera ampliada"       
        }
    });

    useEffect(()=>{
        CountShowData();
        TypePage();

        RegisterListPag('idioma', setIdioma);
        setNameUrl(window.location.href.split("#"));
    }, []); 

    useEffect(()=>{
        CountShowData();
        TypePage();
    }, [page]);

    useEffect(()=>{
        CountShowData();
        TypePage();
    }, [nameUrl]);
    
    function CountShowData(){
        let count = 0;
        const countData = setInterval(() => {
            if(count == 1){
                setShowData(true); 
                clearInterval(countData);
            }
            count++; 
        }, 300);
    }

    function TypePage(){
        switch (page) {
            case 'index':
                return (                    
                    <div className={ showData == true ? "div_data show_data" : "div_data" }>
                        <div className="div_button">
                            <div className="button btn_1">
                                <div className="show_name button_1" onClick={ ()=>{ ClickIdioma('pt_br'); setStatus(true); } }>
                                    Português
                                </div>
                            </div>
                            <div className="button btn_1">
                                <div className="show_name button_1" onClick={ ()=>{ ClickIdioma('en'); setStatus(true); } }>
                                    English
                                </div>
                            </div>
                            <div className="button btn_1">
                                <div className="show_name button_1" onClick={ ()=>{ ClickIdioma('es'); setStatus(true); } }>
                                    Español
                                </div>
                            </div>
                        </div>
                        {
                            status == false ? null :
                            <div className="pop_up">
                                <div className="list_machine">
                                    <div className="close_modal" onClick={ ()=>{ setStatus(false); } }>
                                        <SvgClose className="icons" color="#387C2B" />
                                    </div>
                                    <div className="show_details" onClick={ ()=>{ setPage('upgrades'); setStatus(false) } }>
                                        <div className="mach_title">{ textFixed.text_1[idioma] }</div>
                                    </div>
                                    <div className="show_details" onClick={ ()=>{ setPage('partsandservices'); setStatus(false) } }>
                                        <div className="mach_title">{ textFixed.text_2[idioma] }</div>
                                    </div>
                                    {
                                        props.nameClickPage == 'cotton_forrage' || nameUrl[2] == 'cotton_forrage' ? null :
                                        <div className="show_details" onClick={ ()=>{ setPage('expanded_portfolio'); setStatus(false) } }>
                                            <div className="mach_title">{ textFixed.text_3[idioma] }</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                );

            case "upgrades": 
                return <MesaPartsAndServices_Upgrades origin={ nameUrl[2] } ClickPage={ ClickPage } />;
            
            case "partsandservices":
                return <MesaPartsAndServices_Agricultural origin={ nameUrl[2] } ClickPage={ ClickPage } />;

            case "expanded_portfolio": 
                return <MesaPartsAndServices_ExpandedPortfolio origin={ nameUrl[2] } ClickPage={ ClickPage } />;
        }
    }

    function ClickIdioma(value){
        SetListPag('idioma', value);
    }

    function ClickPage(value){
        setPage(value);
    }
    
    return(
        <div className="MesaPartsAndServices">
            {
                page == "index" ? null : 
                <>
                    <div className="div_idioma" onClick={ ()=>{ setStatusIdioma(true); } }>
                        <SvgIdioma className="icons_idioma" color="#ffffff" />
                    </div>
                    {
                        statusIdioma == false ? null :
                        <div className="pop_up">
                            <div className="list_machine">
                                <div className="close_modal" onClick={ ()=>{ setStatusIdioma(false); } }>
                                    <SvgClose className="icons" color="#387C2B" />
                                </div>
                                <div className="div_button">
                                    <div className="button btn_1">
                                        <div className="show_name button_1" onClick={ ()=>{ ClickIdioma('pt_br'); setStatusIdioma(false); } }>
                                            Português
                                        </div>
                                    </div>
                                    <div className="button btn_1">
                                        <div className="show_name button_1" onClick={ ()=>{ ClickIdioma('en'); setStatusIdioma(false); } }>
                                            English
                                        </div>
                                    </div>
                                    <div className="button btn_1">
                                        <div className="show_name button_1" onClick={ ()=>{ ClickIdioma('es'); setStatusIdioma(false); } }>
                                            Español
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
            <div className="reset_page" onClick={ ()=>{ props.ResetPage('index') } } />
            { TypePage() }
            <div className="div_bg">
                <img alt="img" src="./assets/texture.png" className="texture" />
            </div>         
        </div>
    )
}