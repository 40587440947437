export function SvgHome(props){
    return(
        <svg viewBox="0 0 27 28" className={ props.className } fill="none">
            <path d="M26.0361 13.1073V25.6439C26.0361 26.2194 25.8075 26.7712 25.4006 27.1781C24.9937 27.585 24.4419 27.8136 23.8664 27.8136H18.4422C17.8668 27.8136 17.3149 27.585 16.9081 27.1781C16.5012 26.7712 16.2726 26.2194 16.2726 25.6439V20.2197C16.2726 19.932 16.1583 19.6561 15.9548 19.4526C15.7514 19.2492 15.4754 19.1349 15.1877 19.1349H10.8484C10.5607 19.1349 10.2847 19.2492 10.0813 19.4526C9.87784 19.6561 9.76354 19.932 9.76354 20.2197V25.6439C9.76354 26.2194 9.53495 26.7712 9.12806 27.1781C8.72117 27.585 8.1693 27.8136 7.59387 27.8136H2.16968C1.59424 27.8136 1.04238 27.585 0.635483 27.1781C0.22859 26.7712 2.50704e-08 26.2194 2.50704e-08 25.6439V13.1073C-4.56213e-05 12.807 0.0622415 12.5099 0.182919 12.235C0.303597 11.96 0.480038 11.7131 0.701076 11.5098L11.5495 1.27438L11.5644 1.25947C11.9638 0.896228 12.4843 0.694946 13.0242 0.694946C13.564 0.694946 14.0845 0.896228 14.4839 1.25947C14.4886 1.26477 14.4936 1.26976 14.4989 1.27438L25.3472 11.5098C25.5661 11.7142 25.7402 11.9616 25.8588 12.2365C25.9773 12.5114 26.0377 12.8079 26.0361 13.1073Z" fill={ props.color }/>
        </svg>
    )
}

export function SvgArrow(props){
    return(
        <svg width="82" height="16" className={ props.className } viewBox="0 0 82 16" fill="none">
            <path d="M81 8.00001L1 8" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 15L0.999999 8L8 1" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function SvgClose(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M18 6L6 18" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 6L18 18" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function SvgChecked(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" stroke="#FFDD00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function SvgArrowFile(props){
    return(
        <svg viewBox="0 0 50 202" fill="none" className={ props.className }>
            <path d="M49 0.999998L0.999991 101L49 201" stroke={ props.color } strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function SvgIdioma(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path fill={ props.color } d="M11 1H3C1.9 1 1 1.9 1 3V15L4 12H9V11C9 8.8 10.79 7 13 7V3C13 1.9 12.1 1 11 1M11 4L9.5 4C9.16 5.19 8.54 6.3 7.68 7.26L7.66 7.28L8.92 8.53L8.55 9.54L7 8L4.5 10.5L3.81 9.77L6.34 7.28C5.72 6.59 5.22 5.82 4.86 5H5.85C6.16 5.6 6.54 6.17 7 6.68C7.72 5.88 8.24 4.97 8.57 4L3 4V3H6.5V2H7.5V3H11V4M21 9H13C11.9 9 11 9.9 11 11V18C11 19.1 11.9 20 13 20H20L23 23V11C23 9.9 22.1 9 21 9M19.63 19L18.78 16.75H15.22L14.38 19H12.88L16.25 10H17.75L21.13 19H19.63M17 12L18.22 15.25H15.79L17 12Z"/>
        </svg>
    )
}