import { useState, useEffect } from "react";

import './MesaUnimil.css';

import Axios from 'axios';

import { GetDataPage, RegisterDataPage, SetListData } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import MesaUnimil_Home from "./Home";
import MesaUnimil_UnimilCana from "./UnimilCana";
import { SvgClose, SvgIdioma } from "components/Svg";

export default function MesaUnimil(props){

    const [ showData, setShowData ] = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('unimil'));
    const [ page, setPage ]         = useState('index');

    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ status, setStatus ] = useState(false);

    useEffect(()=>{
        RegisterDataPage('unimil', setDataPage);
        RegisterListPag('idioma', setIdioma);
        CountShowData();
        TypePage();
    }, []); 

    useEffect(()=>{
        CountShowData();
        TypePage();
    }, [page]);

    useEffect(()=>{
        TypePage();
    }, [idioma]);
    
    function CountShowData(){
        let count = 0;
        const countData = setInterval(() => {
            if(count == 1){
                setShowData(true); 
                clearInterval(countData);
            }
            count++; 
        }, 300);
    }

    function TypePage(){
        switch (page) {
            case 'index':
                return (
                    <div className="div_button">
                        <div className="button btn_1">
                            <div className="show_name button_1" onClick={ ()=>{ setPage('UnimilCana'); ClickIdioma('pt_br'); } }>
                                Português
                            </div>
                        </div>
                        <div className="button btn_1">
                            <div className="show_name button_1" onClick={ ()=>{ setPage('UnimilCana'); ClickIdioma('en'); } }>
                                English
                            </div>
                        </div>
                        <div className="button btn_1">
                            <div className="show_name button_1" onClick={ ()=>{ setPage('UnimilCana'); ClickIdioma('es'); } }>
                                Español
                            </div>
                        </div>
                    </div>
                );

            case 'UnimilCana':
                return <MesaUnimil_UnimilCana ClickPage={ ClickPage } />;
        }
    }

    function ClickPage(value){
        setPage(value);
    }

    function ClickIdioma(value){
        SetListPag('idioma', value);
    }

    return(
        <div className="MesaUnimil">
            <div className="reset_page" onClick={ ()=>{ props.ResetPage('index') } } />
            {
                page == 'index' ? null : 
                <>
                    <div className="div_idioma" onClick={ ()=>{ setPage('index'); } }>
                        <SvgIdioma className="icons_idioma" color="#ffffff" />
                    </div>
                    {/* {
                        status == false ? null :
                        <div className="pop_up">
                            <div className="list_machine">
                                <div className="close_modal" onClick={ ()=>{ setStatus(false); } }>
                                    <SvgClose className="icons" color="#387C2B" />
                                </div>
                                <div className="div_button">
                                    <div className="button btn_1">
                                        <div className="show_name button_1" onClick={ ()=>{ ClickIdioma('pt_br'); setPage('index'); setStatus(false); } }>
                                            Português
                                        </div>
                                    </div>
                                    <div className="button btn_1">
                                        <div className="show_name button_1" onClick={ ()=>{ ClickIdioma('en'); setPage('index'); setStatus(false); } }>
                                            English
                                        </div>
                                    </div>
                                    <div className="button btn_1">
                                        <div className="show_name button_1" onClick={ ()=>{ ClickIdioma('es'); setPage('index'); setStatus(false); } }>
                                            Español
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } */}
                </>
            }
            <div className={ showData == true ? "div_data show_data" : "div_data" }>
                {
                    TypePage()
                }
            </div>
            <div className="div_bg">
                <img alt="img" src="./assets/texture.png" className="texture" />
            </div>
        </div>
    )
}