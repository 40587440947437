import { useState, useEffect } from "react";

import './Agricultural.css';

import { SvgArrow, SvgArrowFile, SvgClose, SvgHome } from "components/Svg";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";

export default function MesaPartsAndServices_Agricultural(props){

    const [ nameUrl, setNameUrl ]         = useState(window.location.href.split("#"));
    const [ currentPage, setCurrentPage ] = useState('index');
    const [ pageDetails, setPageDetails ] = useState('list_details');
    const [ idDetails, setIdDetails ]     = useState(0);
    const [ idioma, setIdioma ]           = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ]       = useState(GetDataPage('partsAndServices'));
    const [ showData, setShowData ]       = useState(dataPage[nameUrl[2]]);
    
    // origin - planting
    const [ subMenu_1, setSubMenu_1 ] = useState(dataPage.parts_and_service_planters);
    const [ subMenu_2, setSubMenu_2 ] = useState(dataPage.parts_and_service_tractors);

    // origin - cotton_forrage
    const [ subMenu_3, setSubMenu_3 ] = useState(dataPage.parts_and_service_consumables);
    const [ subMenu_4, setSubMenu_4 ] = useState(dataPage.parts_and_service_cotton_harvest);
    const [ subMenu_5, setSubMenu_5 ] = useState(dataPage.parts_and_service_forage_harvest);

    // page - left
    let dataLeft       = [];
    let pageLeft       = 0;
    let startItensLeft = 0;
    let endItensLeft   = 0;
    // page - right
    let dataRight       = [];
    let pageRight       = 0;
    let startItensRight = 0;
    let endItensRight   = 0;

    const [ textFixed, setTextFixed ] = useState({
        "text_1": {
            "pt_br": "Peças e serviços",
            "en": "Parts and service",
            "es": "Partes y Servicio"       
        },
        "text_2": {
            "pt_br": "PREPARO",
            "en": "TILLAGE",
            "es": "PREPARACIÓN"
        },
        "text_3": {
            "pt_br": "PLANTIO",
            "en": "PLANTING",
            "es": "PLANTACIÓN"
        },
        "text_4": {
            "pt_br": "APPLYING",
            "en": "APPLYING",
            "es": "APPLYING"
        },
        "text_5": {
            "pt_br": "COLHEITA GRÃOS",
            "en": "GRAIN HARVEST",
            "es": "COSECHA DE GRANOS"
        },
        "text_6": {
            "pt_br": "COLHEITA CANA",
            "en": "SUGARCANE HARVEST",
            "es": "COSECHA DE CAÑA"
        },
        "text_7": {
            "pt_br": "COLHEITA ALGODÃO",
            "en": "COTTON & FORRAGE",
            "es": "ALGODÓN Y FORRAJE"
        },
        "text_8": {
            "pt_br": "TRATOR UTILITÁRIO",
            "en": "UTILITY TRACTOR",
            "es": "TRACTOR UTILITARIO"
        },

        "text_9": {
            "pt_br": "Plantadeiras",
            "en": "Planters",
            "es": "Sembradoras"       
        },
        "text_10": {
            "pt_br": "Tratores Séries 7, 8 e 9",
            "en": "Tractors series 7, 8 and 9",
            "es": "Tractores Series 7, 8 y 9"
        }
        ,
        "text_11": {
            "pt_br": "Consumíveis",
            "en": "Consumables",
            "es": "Consumibles"
        },
        "text_12": {
            "pt_br": "Colheita de Algodão",
            "en": "Cotton Harvesting",
            "es": "Cosecha de Algodón"
        },
        "text_13": {
            "pt_br": "Colheita de Forragem",
            "en": "Forage Harvesters",
            "es": "Forage Harvesters"
        }
    });

    const [ next, setNext ]           = useState('');
    const [ prev, setPrev ]           = useState('');
    const [ namePage, setNamePage ]   = useState(ShowNamePage());
    const [ typeClick, setTypeClick ] = useState('');

    const [ status, setStatus ]         = useState(false);    
    const [ openIndex, setOpenIndex ]   = useState('');
    const [ openFile, setOpenFile ]     = useState('');
    const [ statusFile, setStatusFile ] = useState(false);

    const [ nameSubMenu, setNameSubMenu ]     = useState(0);
    const [ statusSubMenu, setStatusSubMenu ] = useState(false);

    let countLeft  = 0;
    let countRight = 0;
    let countImg   = 0;

    const [ showPage, setShowPage ]   = useState(0);

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('partsAndServices', setDataPage);
    }, []);

    useEffect(()=>{
        ListDataClick();
        setNamePage(ShowNamePage());
    }, [idioma]);

    function ShowNamePage(){
        switch (nameUrl[2]) {
            case "tillage":
                return textFixed.text_2[idioma];

            case "planting":
                return textFixed.text_3[idioma];

            case "applying":
                return textFixed.text_4[idioma];

            case "grain_harvest":
                return textFixed.text_5[idioma];

            case "sugarcane_harvest":
                return textFixed.text_6[idioma];

            case "cotton_forrage":
                return textFixed.text_7[idioma];

            case "utility_tractor":
                return textFixed.text_8[idioma];
        }
    }

    function ListDataClick(){
        return(
            <>
                <div className="div_topic">
                    <div className="icon_return">
                        <div className="return" onClick={ ()=>{ props.ClickPage('index') } }>
                            <SvgHome className="icon_home" color="#FFDD00" />
                        </div>
                        <div className="name_current_page">
                            <div className="show_name">/</div>
                            <div className="show_name" onClick={ ()=>{ setCurrentPage('index'); setPageDetails('list_details'); setNameSubMenu(0) } }>
                                { showData.name[idioma] }
                            </div>
                            <div className="show_name">/</div>
                            <div className="show_name" onClick={ ()=>{ setCurrentPage('index'); setPageDetails('list_details'); setNameSubMenu(0) } }>
                                { textFixed.text_1[idioma] }
                            </div>
                            {/* {
                                pageDetails == 'list_details' ? null :
                                <>
                                    <div className="show_name">/</div>
                                    <div className="show_name">{ pageDetails }</div>
                                </>
                            } */}
                        </div>
                    </div>
                </div>
                { ShowDetailsClick() }
            </>
        )
    }

    function OpenPopUP(type, id){
        if(type == 0){
            document.getElementById(id).classList.add('open_popup');
            document.getElementById(id).classList.remove('close_popup');
        }
        if(type == 1){
            document.getElementById(id).classList.remove('open_popup');
            document.getElementById(id).classList.add('close_popup');
        }
    }

    function ShowDetailsClick(){
        let buttonsLeft      = [];
        let countButtonsLeft = [];
        let totalLeft = 0;
        
        let buttonsRight      = [];
        let countButtonsRight = [];
        let totalRight = 0;

        switch (pageDetails) {
            case textFixed.text_9[idioma]:  
                    buttonsLeft    = subMenu_1.button.filter(item =>item.position === 'left');
                    dataLeft       = buttonsLeft.filter(item =>item.name[idioma] != '');
                    pageLeft       = Math.ceil(dataLeft.length / 6);
                    startItensLeft = dataLeft * 6;
                    endItensLeft   = startItensLeft + 6;

                    totalLeft         = dataLeft.length;
                    countButtonsLeft = dataLeft.slice(startItensLeft, endItensLeft); 

                    buttonsRight    = subMenu_1.button.filter(item =>item.position === 'right');
                    dataRight       = buttonsRight.filter(item =>item.name[idioma] != '');
                    pageRight       = Math.ceil(dataRight.length / 6);
                    startItensRight = dataRight * 6;
                    endItensRight   = startItensRight + 6;

                    totalRight        = dataRight.length;
                    countButtonsRight = dataRight.slice(startItensRight, endItensRight);                    
                break;
            
            case textFixed.text_10[idioma]:  
                    buttonsLeft    = subMenu_2.button.filter(item =>item.position === 'left');
                    dataLeft       = buttonsLeft.filter(item =>item.name[idioma] != '');
                    pageLeft       = Math.ceil(dataLeft.length / 6);
                    startItensLeft = dataLeft * 6;
                    endItensLeft   = startItensLeft + 6;

                    totalLeft         = dataLeft.length;
                    countButtonsLeft = dataLeft.slice(startItensLeft, endItensLeft); 

                    buttonsRight    = subMenu_2.button.filter(item =>item.position === 'right');
                    dataRight       = buttonsRight.filter(item =>item.name[idioma] != '');
                    pageRight       = Math.ceil(dataRight.length / 6);
                    startItensRight = dataRight * 6;
                    endItensRight   = startItensRight + 6;

                    totalRight        = dataRight.length;
                    countButtonsRight = dataRight.slice(startItensRight, endItensRight);    
                break;

            case textFixed.text_11[idioma]: 
                    buttonsLeft    = subMenu_3.button.filter(item =>item.position === 'left');
                    dataLeft       = buttonsLeft.filter(item =>item.name[idioma] != '');
                    pageLeft       = Math.ceil(dataLeft.length / 6);
                    startItensLeft = dataLeft * 6;
                    endItensLeft   = startItensLeft + 6;

                    totalLeft         = dataLeft.length;
                    countButtonsLeft = dataLeft.slice(startItensLeft, endItensLeft); 

                    buttonsRight    = subMenu_3.button.filter(item =>item.position === 'right');
                    dataRight       = buttonsRight.filter(item =>item.name[idioma] != '');
                    pageRight       = Math.ceil(dataRight.length / 6);
                    startItensRight = dataRight * 6;
                    endItensRight   = startItensRight + 6;

                    totalRight        = dataRight.length;
                    countButtonsRight = dataRight.slice(startItensRight, endItensRight);                             
                break;

            case textFixed.text_12[idioma]:  
                    buttonsLeft    = subMenu_4.button.filter(item =>item.position === 'left');
                    dataLeft       = buttonsLeft.filter(item =>item.name[idioma] != '');
                    pageLeft       = Math.ceil(dataLeft.length / 6);
                    startItensLeft = dataLeft * 6;
                    endItensLeft   = startItensLeft + 6;

                    totalLeft         = dataLeft.length;
                    countButtonsLeft = dataLeft.slice(startItensLeft, endItensLeft); 

                    buttonsRight    = subMenu_4.button.filter(item =>item.position === 'right');
                    dataRight       = buttonsRight.filter(item =>item.name[idioma] != '');
                    pageRight       = Math.ceil(dataRight.length / 6);
                    startItensRight = dataRight * 6;
                    endItensRight   = startItensRight + 6;

                    totalRight        = dataRight.length;
                    countButtonsRight = dataRight.slice(startItensRight, endItensRight);                           
                break;

            case textFixed.text_13[idioma]: 
                    buttonsLeft    = subMenu_5.button.filter(item =>item.position === 'left');
                    dataLeft       = buttonsLeft.filter(item =>item.name[idioma] != '');
                    pageLeft       = Math.ceil(dataLeft.length / 6);
                    startItensLeft = dataLeft * 6;
                    endItensLeft   = startItensLeft + 6;

                    totalLeft         = dataLeft.length;
                    countButtonsLeft = dataLeft.slice(startItensLeft, endItensLeft); 

                    buttonsRight    = subMenu_5.button.filter(item =>item.position === 'right');
                    dataRight       = buttonsRight.filter(item =>item.name[idioma] != '');
                    pageRight       = Math.ceil(dataRight.length / 6);
                    startItensRight = dataRight * 6;
                    endItensRight   = startItensRight + 6;

                    totalRight        = dataRight.length;
                    countButtonsRight = dataRight.slice(startItensRight, endItensRight); 
                break;

            default:        
                    buttonsLeft      = showData.button.filter(item =>item.position === 'left');
                    dataLeft         = buttonsLeft.filter(item =>item.name[idioma] != '');
                    pageLeft         = Math.ceil(dataLeft.length / 6);
                    startItensLeft   = showPage * 6;
                    endItensLeft     = startItensLeft + 6;
                    totalLeft        = dataLeft.length;
                    countButtonsLeft = dataLeft.slice(startItensLeft, endItensLeft); 

                    buttonsRight    = showData.button.filter(item =>item.position === 'right');
                    dataRight       = buttonsRight.filter(item =>item.name[idioma] != '');
                    pageRight       = Math.ceil(dataRight.length / 6);
                    startItensRight = showPage * 6;
                    endItensRight   = startItensRight + 6;
                    totalRight      = dataRight.length;
                    countButtonsRight = dataRight.slice(startItensRight, endItensRight); 
                break;
        }

        if(nameUrl[2] == 'planting'){
            totalRight = totalRight + 2;
        }
        if(nameUrl[2] == 'cotton_forrage'){
            totalRight = totalRight + 3;
        }

        if(pageDetails == 'list_details'){
            return(
                <>
                    <div className="div_name_page">
                        <div className="name_page">{ showData.name[idioma] }</div>
                    </div>
                    <div className="show_buttons">
                        <div className={ totalLeft > 5 ? "div_buttons alt_gap" : "div_buttons" }>
                            {
                                countButtonsLeft.map((key, index)=>{
                                    countLeft = countLeft + 1;
                                    return(
                                        <div className={ totalLeft > 5 ? "alt_width name_button border_left left_" + countLeft : "name_button border_left left_" + countLeft } key={ index } onClick={ ()=>{ setPageDetails(key.name[idioma]); setIdDetails(key.id); setPrev(index - 1); setNext(index + 1); setTypeClick('left') } }>
                                            <span className="space_name" dangerouslySetInnerHTML={ { __html: key.name[idioma] ? key.name[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={ totalRight > 5 ? "div_buttons alt_gap" : "div_buttons" }>
                            {
                                countButtonsRight.map((key, index)=>{
                                    countRight = countRight + 1;
                                    return(
                                        <div className={ totalRight > 5 ? "alt_width name_button border_right right_" + countRight : "name_button border_right right_" + countRight } key={ index } onClick={ ()=>{ setPageDetails(key.name[idioma]); setIdDetails(key.id); setPrev(index - 1); setNext(index + 1); setTypeClick('right') } }>
                                            <span className="space_name" dangerouslySetInnerHTML={ { __html: key.name[idioma] ? key.name[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                        </div>
                                    )
                                })
                            }
                            {
                                nameUrl[2] == 'planting' ? 
                                <>                                
                                    <div className={ totalRight > 5 ? "alt_width name_button border_right right_aa" : "name_button border_right right_aa" } onClick={ ()=>{ setPageDetails(textFixed.text_9[idioma]); } }>
                                        <span className="space_name">
                                            { textFixed.text_9[idioma] }
                                        </span>
                                    </div>                    
                                    <div className={ totalRight > 5 ? "alt_width name_button border_right right_aa" : "name_button border_right right_aa" } onClick={ ()=>{ setPageDetails(textFixed.text_10[idioma]); } }>
                                        <span className="space_name">
                                            { textFixed.text_10[idioma] }
                                        </span>
                                    </div>
                                </> : null
                            }
                            {
                                nameUrl[2] == 'cotton_forrage' ? 
                                <>                                
                                    <div className={ totalRight > 5 ? "alt_width name_button border_right right_aa" : "name_button border_right right_aa" } onClick={ ()=>{ setPageDetails(textFixed.text_11[idioma]); } }>
                                        <span className="space_name">
                                            { textFixed.text_11[idioma] }
                                        </span>
                                    </div> 
                                    <div className={ totalRight > 5 ? "alt_width name_button border_right right_aa" : "name_button border_right right_aa" } onClick={ ()=>{ setPageDetails(textFixed.text_12[idioma]); } }>
                                        <span className="space_name">
                                            { textFixed.text_12[idioma] }
                                        </span>
                                    </div> 
                                    {
                                        subMenu_5.button.length > 0 ? 
                                        <div className={ totalRight > 5 ? "alt_width name_button border_right right_aa" : "name_button border_right right_aa" } onClick={ ()=>{ setPageDetails(textFixed.text_13[idioma]); } }>
                                            <span className="space_name">
                                                { textFixed.text_13[idioma] }
                                            </span>
                                        </div> : null
                                    }
                                </> : null
                            }
                        </div>
                        {
                            statusSubMenu == false ? null :
                            <div className="pop_up">
                                <div className="list_machine">
                                    <div className="close_modal" onClick={ ()=>{ setStatusSubMenu(false); } }>
                                        <SvgClose className="icons" color="#387C2B" />
                                    </div>
                                    {
                                        nameUrl[2] == 'planting' ? 
                                        <>
                                            {
                                                subMenu_1.button.length > 0 ?
                                                <div className="show_details" onClick={ ()=>{ setPageDetails(textFixed.text_9[idioma]); setStatusSubMenu(false) } }>
                                                    <div className="mach_title">{ textFixed.text_9[idioma] }</div>
                                                </div> : null
                                            }
                                            {
                                                subMenu_2.button.length > 0 ?
                                                <div className="show_details" onClick={ ()=>{ setPageDetails(textFixed.text_10[idioma]); setStatusSubMenu(false) } }>
                                                    <div className="mach_title">{ textFixed.text_10[idioma] }</div>
                                                </div> : null
                                            }
                                        </> : 
                                            nameUrl[2] == 'cotton_forrage' ? 
                                            <>
                                                {
                                                    subMenu_3.button.length > 0 ?
                                                    <div className="show_details" onClick={ ()=>{ setPageDetails(textFixed.text_11[idioma]); setStatusSubMenu(false) } }>
                                                        <div className="mach_title">{ textFixed.text_11[idioma] }</div>
                                                    </div> : null
                                                }
                                                {
                                                    subMenu_4.button.length > 0 ?
                                                    <div className="show_details" onClick={ ()=>{ setPageDetails(textFixed.text_12[idioma]); setStatusSubMenu(false) } }>
                                                        <div className="mach_title">{ textFixed.text_12[idioma] }</div>
                                                    </div> : null
                                                }
                                                {
                                                    subMenu_5.button.length > 0 ?
                                                    <div className="show_details" onClick={ ()=>{ setPageDetails(textFixed.text_13[idioma]); setStatusSubMenu(false) } }>
                                                        <div className="mach_title">{ textFixed.text_13[idioma] }</div>
                                                    </div> : null
                                                }
                                            </> : null
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div className="list_opt_page">                        
                        {
                            pageLeft > pageRight ? 
                                Array.from(Array(pageLeft), (item, index)=>{
                                    return(
                                        <div className={ showPage == index ? "page_number page_active" : "page_number" } key={ index } onClick={ (e)=>{ setShowPage(index); RestartAnimation() } }>
                                            { index + 1 }
                                        </div>
                                    )
                                }) :
                                Array.from(Array(pageRight), (item, index)=>{
                                    return(
                                        <div className={ showPage == index ? "page_number page_active" : "page_number" } key={ index } onClick={ (e)=>{ setShowPage(index); RestartAnimation() } }>
                                            { index + 1 }
                                        </div>
                                    )
                                }) 
                        }
                    </div>
                    <div className="div_img">
                        <img alt="img" src={ showData.img[idioma] } className="bg_img" />
                    </div>
                </>
            )
        }else {
            if(pageDetails == textFixed.text_9[idioma] || pageDetails == textFixed.text_10[idioma] || pageDetails == textFixed.text_11[idioma] || pageDetails == textFixed.text_12[idioma] || pageDetails == textFixed.text_13[idioma]){
                let typePage      = '';
                let showDataLeft  = [];
                let showDataRight = [];
                if(nameUrl[2] == 'planting'){
                    if(pageDetails == textFixed.text_9[idioma]){
                        typePage = 'submenu_1';
                        // menu 1
                        const btnLeftFixed_1      = subMenu_1.button.filter(item =>item.position === 'left');
                        const countBtnLeftFixed_1 = btnLeftFixed_1.filter(item =>item.name[idioma] != '');
                        countBtnLeftFixed_1.map((key, index)=>{
                            showDataLeft.push(key);
                        })
                        showDataLeft.sort(function(a, b) {
                            if(a.name[idioma] < b.name[idioma]) {
                                return -1;
                            } else {
                                return true;
                            }
                        });
                        
                        buttonsLeft    = subMenu_5.button.filter(item =>item.position === 'left');
                        dataLeft       = buttonsLeft.filter(item =>item.name[idioma] != '');
                        pageLeft       = Math.ceil(dataLeft.length / 6);
                        startItensLeft = dataLeft * 6;
                        endItensLeft   = startItensLeft + 6;

                        totalLeft         = dataLeft.length;
                        countButtonsLeft = dataLeft.slice(startItensLeft, endItensLeft); 




                        const btbRightFixed_1     = subMenu_1.button.filter(item =>item.position === 'right');
                        const countButtonsRight_1 = btbRightFixed_1.filter(item =>item.name[idioma] != '');
                        countButtonsRight_1.map((key, index)=>{
                            showDataRight.push(key);
                        })
                        showDataRight.sort(function(a, b) {
                            if(a.name[idioma] < b.name[idioma]) {
                                return -1;
                            } else {
                                return true;
                            }
                        });

                    }else if(pageDetails == textFixed.text_10[idioma]){
                        typePage = 'submenu_2';
                        // menu 2
                        const btnLeftFixed_2      = subMenu_2.button.filter(item =>item.position === 'left');
                        const countBtnLeftFixed_2 = btnLeftFixed_2.filter(item =>item.name[idioma] != '');
                        countBtnLeftFixed_2.map((key, index)=>{
                            showDataLeft.push(key);
                        })
                        showDataLeft.sort(function(a, b) {
                            if(a.name[idioma] < b.name[idioma]) {
                                return -1;
                            } else {
                                return true;
                            }
                        });

                        const btbRightFixed_2     = subMenu_2.button.filter(item =>item.position === 'right');
                        const countButtonsRight_2 = btbRightFixed_2.filter(item =>item.name[idioma] != '');
                        countButtonsRight_2.map((key, index)=>{
                            showDataRight.push(key);
                        })
                        showDataRight.sort(function(a, b) {
                            if(a.name[idioma] < b.name[idioma]) {
                                return -1;
                            } else {
                                return true;
                            }
                        });
                    }                    
                }

                if(nameUrl[2] == 'cotton_forrage'){
                    if(pageDetails == textFixed.text_11[idioma]){
                        typePage = 'submenu_3';
                        // menu 1
                        const btnLeftFixed_1      = subMenu_3.button.filter(item =>item.position === 'left');
                        const countBtnLeftFixed_1 = btnLeftFixed_1.filter(item =>item.name[idioma] != '');
                        countBtnLeftFixed_1.map((key, index)=>{
                            showDataLeft.push(key);
                        })
                        showDataLeft.sort(function(a, b) {
                            if(a.name[idioma] < b.name[idioma]) {
                                return -1;
                            } else {
                                return true;
                            }
                        });

                        const btbRightFixed_1     = subMenu_3.button.filter(item =>item.position === 'right');
                        const countButtonsRight_1 = btbRightFixed_1.filter(item =>item.name[idioma] != '');
                        countButtonsRight_1.map((key, index)=>{
                            showDataRight.push(key);
                        })
                        showDataRight.sort(function(a, b) {
                            if(a.name[idioma] < b.name[idioma]) {
                                return -1;
                            } else {
                                return true;
                            }
                        });

                    }else if(pageDetails == textFixed.text_12[idioma]){
                        typePage = 'submenu_4';
                        // menu 2
                        const btnLeftFixed_2      = subMenu_4.button.filter(item =>item.position === 'left');
                        const countBtnLeftFixed_2 = btnLeftFixed_2.filter(item =>item.name[idioma] != '');
                        countBtnLeftFixed_2.map((key, index)=>{
                            showDataLeft.push(key);
                        })
                        showDataLeft.sort(function(a, b) {
                            if(a.name[idioma] < b.name[idioma]) {
                                return -1;
                            } else {
                                return true;
                            }
                        });

                        const btbRightFixed_2     = subMenu_4.button.filter(item =>item.position === 'right');
                        const countButtonsRight_2 = btbRightFixed_2.filter(item =>item.name[idioma] != '');
                        countButtonsRight_2.map((key, index)=>{
                            showDataRight.push(key);
                        })
                        showDataRight.sort(function(a, b) {
                            if(a.name[idioma] < b.name[idioma]) {
                                return -1;
                            } else {
                                return true;
                            }
                        });
                        
                    }else if(pageDetails == textFixed.text_13[idioma]){
                        typePage = 'submenu_5';
                        // menu 2
                        const btnLeftFixed_2      = subMenu_5.button.filter(item =>item.position === 'left');
                        const countBtnLeftFixed_2 = btnLeftFixed_2.filter(item =>item.name[idioma] != '');
                        countBtnLeftFixed_2.map((key, index)=>{
                            showDataLeft.push(key);
                        })
                        showDataLeft.sort(function(a, b) {
                            if(a.name[idioma] < b.name[idioma]) {
                                return -1;
                            } else {
                                return true;
                            }
                        });

                        const btbRightFixed_2     = subMenu_5.button.filter(item =>item.position === 'right');
                        const countButtonsRight_2 = btbRightFixed_2.filter(item =>item.name[idioma] != '');
                        countButtonsRight_2.map((key, index)=>{
                            showDataRight.push(key);
                        })
                        showDataRight.sort(function(a, b) {
                            if(a.name[idioma] < b.name[idioma]) {
                                return -1;
                            } else {
                                return true;
                            }
                        });
                    }

                }

                return(
                    <>
                        <div className="div_name_page">
                            <div className="name_page">{ textFixed.text_1[idioma] }</div>
                        </div>
                        <div className="show_buttons">
                            <div className="div_buttons alt_gap">
                                {
                                    showDataLeft.map((key, index)=>{
                                        countLeft = countLeft + 1;
                                        return(
                                            <div className={ "alt_width name_button border_left left_" + countLeft } key={ index } onClick={ ()=>{ setPageDetails(key.name[idioma]); setIdDetails(key.id); setPrev(index - 1); setNext(index + 1); setTypeClick('left'); setNameSubMenu(typePage) } }>
                                                <span className="space_name" dangerouslySetInnerHTML={ { __html: key.name[idioma] ? key.name[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="div_buttons alt_gap">
                                {
                                    showDataRight.map((key, index)=>{
                                        countRight = countRight + 1;
                                        return(
                                            <div className={ "alt_width name_button border_right right_" + countRight } key={ index } onClick={ ()=>{ setPageDetails(key.name[idioma]); setIdDetails(key.id); setPrev(index - 1); setNext(index + 1); setTypeClick('right'); setNameSubMenu(typePage) } }>
                                                <span className="space_name" dangerouslySetInnerHTML={ { __html: key.name[idioma] ? key.name[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="div_img">
                            <img alt="img" src={ showData.img[idioma] } className="bg_img" />
                        </div>
                    </>
                )

            }else{
                let newData = [];
                let nameLeftBtnClick    = '';
                let nameLeftBtnClick_id = '';

                let nameRightBtnClick    = '';
                let nameRightBtnClick_id = '';
                if(nameSubMenu != 0){
                    switch (nameSubMenu) {
                        case 'submenu_1':   
                                newData = subMenu_1.button.find(item => item.id == idDetails);  
                                buttonsLeft       = subMenu_1.button.filter(item =>item.position === 'left');
                                countButtonsLeft  = buttonsLeft.filter(item =>item.name[idioma] != '');
                                totalLeft = countButtonsLeft.length;

                                buttonsRight      = subMenu_1.button.filter(item =>item.position === 'right');
                                countButtonsRight = buttonsRight.filter(item =>item.name[idioma] != '');
                                totalRight = countButtonsRight.length;                        
                            break;
                        case 'submenu_2':   
                                newData = subMenu_2.button.find(item => item.id == idDetails); 
                                buttonsLeft       = subMenu_2.button.filter(item =>item.position === 'left');
                                countButtonsLeft  = buttonsLeft.filter(item =>item.name[idioma] != '');
                                totalLeft = countButtonsLeft.length;

                                buttonsRight      = subMenu_2.button.filter(item =>item.position === 'right');
                                countButtonsRight = buttonsRight.filter(item =>item.name[idioma] != '');
                                totalRight = countButtonsRight.length;                            
                            break;
                        case 'submenu_3':  
                                newData = subMenu_3.button.find(item => item.id == idDetails);
                                buttonsLeft       = subMenu_3.button.filter(item =>item.position === 'left');
                                countButtonsLeft  = buttonsLeft.filter(item =>item.name[idioma] != '');
                                totalLeft = countButtonsLeft.length;

                                buttonsRight      = subMenu_3.button.filter(item =>item.position === 'right');
                                countButtonsRight = buttonsRight.filter(item =>item.name[idioma] != '');
                                totalRight = countButtonsRight.length;                              
                            break;
                        case 'submenu_4': 
                                newData = subMenu_4.button.find(item => item.id == idDetails); 
                                buttonsLeft       = subMenu_4.button.filter(item =>item.position === 'left');
                                countButtonsLeft  = buttonsLeft.filter(item =>item.name[idioma] != '');
                                totalLeft = countButtonsLeft.length;

                                buttonsRight      = subMenu_4.button.filter(item =>item.position === 'right');
                                countButtonsRight = buttonsRight.filter(item =>item.name[idioma] != '');
                                totalRight = countButtonsRight.length;                              
                            break;
                        case 'submenu_5': 
                                newData = subMenu_5.button.find(item => item.id == idDetails);  
                                buttonsLeft       = subMenu_5.button.filter(item =>item.position === 'left');
                                countButtonsLeft  = buttonsLeft.filter(item =>item.name[idioma] != '');
                                totalLeft = countButtonsLeft.length;

                                buttonsRight      = subMenu_5.button.filter(item =>item.position === 'right');
                                countButtonsRight = buttonsRight.filter(item =>item.name[idioma] != '');
                                totalRight = countButtonsRight.length;   
                            break;
                    }
                    if(typeClick == 'left'){
                        if(prev == '-1'){
                            nameLeftBtnClick    = "Voltar";
                            nameLeftBtnClick_id = 0;
                        }else {
                            nameLeftBtnClick    = countButtonsLeft[prev].name[idioma];
                            nameLeftBtnClick_id = countButtonsLeft[prev].id;
                        }
                        if(next >= countButtonsLeft.length){
                            let newDataRight     = [];
                            let newDataRightNext = [];
                            switch (nameSubMenu) {
                                case 'submenu_1':
                                        newDataRight = subMenu_1.button.filter(item =>item.position == 'right');
                                        newDataRightNext = newDataRight.filter(item =>item.name[idioma] != '');
                                    break;
                                case 'submenu_2':
                                        newDataRight = subMenu_2.button.filter(item =>item.position == 'right');
                                        newDataRightNext = newDataRight.filter(item =>item.name[idioma] != '');
                                    break;
                                case 'submenu_3':
                                        newDataRight = subMenu_3.button.filter(item =>item.position == 'right');
                                        newDataRightNext = newDataRight.filter(item =>item.name[idioma] != '');
                                    break;
                                case 'submenu_4':
                                        newDataRight = subMenu_4.button.filter(item =>item.position == 'right');
                                        newDataRightNext = newDataRight.filter(item =>item.name[idioma] != '');
                                    break;
                                case 'submenu_5':
                                        newDataRight = subMenu_5.button.filter(item =>item.position == 'right');
                                        newDataRightNext = newDataRight.filter(item =>item.name[idioma] != '');
                                    break;
                            }
                            if(newDataRightNext.length > 0){
                                setPrev(0);
                                setNext(1);
                                setTypeClick('right');
                            }else {
                                nameRightBtnClick    = '';
                                nameRightBtnClick_id = 0;
                            }
                        }else {
                            nameRightBtnClick = countButtonsLeft[next].name[idioma];
                            nameRightBtnClick_id = countButtonsLeft[next].id;
                        }

                    }else if(typeClick == 'right'){
                        if(prev == '-1'){
                            nameLeftBtnClick    = "Voltar";
                            nameLeftBtnClick_id = 0;
                        }else {
                            nameLeftBtnClick    = countButtonsRight[prev].name[idioma];
                            nameLeftBtnClick_id = countButtonsRight[prev].id;
                        }
                        if(next >= countButtonsRight.length){
                            nameRightBtnClick = '';
                            nameRightBtnClick_id = 0;
                        }else {
                            nameRightBtnClick    = countButtonsRight[next].name[idioma];
                            nameRightBtnClick_id = countButtonsRight[next].id;
                        }
                    }
                }else {
                    newData = showData.button.find(item => item.id == idDetails);
                    if(typeClick == 'left'){
                        if(prev == '-1'){
                            nameLeftBtnClick    = "Voltar";
                            nameLeftBtnClick_id = 0;
                        }else {
                            nameLeftBtnClick    = countButtonsLeft[prev].name[idioma];
                            nameLeftBtnClick_id = countButtonsLeft[prev].id;
                        }
                        if(next >= countButtonsLeft.length){
                            const newDataRight = showData.button.filter(item =>item.position == 'right');
                            const newDataRightNext = newDataRight.filter(item =>item.name[idioma] != '');
                            if(newDataRightNext.length > 0){
                                setPrev(0);
                                setNext(1);
                                setTypeClick('right');
                            }else {
                                nameRightBtnClick    = '';
                                nameRightBtnClick_id = 0;
                            }
                        }else {
                            nameRightBtnClick = countButtonsLeft[next].name[idioma];
                            nameRightBtnClick_id = countButtonsLeft[next].id;
                        }

                    }else if(typeClick == 'right'){
                        if(prev == '-1'){
                            nameLeftBtnClick    = "Voltar";
                            nameLeftBtnClick_id = 0;
                        }else {
                            nameLeftBtnClick    = countButtonsRight[prev].name[idioma];
                            nameLeftBtnClick_id = countButtonsRight[prev].id;
                        }
                        if(next >= countButtonsRight.length){
                            nameRightBtnClick = '';
                            nameRightBtnClick_id = 0;
                        }else {
                            nameRightBtnClick    = countButtonsRight[next].name[idioma];
                            nameRightBtnClick_id = countButtonsRight[next].id;
                        }
                    }
                }
                    
                return(
                    <>
                        <div className="name_page_click">
                            <div className="show_name_page_click">
                                <div>{ pageDetails }</div>
                                <div className={ newData.stamp_1 != '' && newData.stamp_2 != '' ? "stamp" : "stamp stamp_one" }>
                                    {
                                        newData.stamp_1 == '' ? null :
                                        <div className="div_stamp">
                                            <img alt="img" src={ newData.stamp_1 } className="stamp_img" />
                                        </div>
                                    }
                                    {
                                        newData.stamp_2 == '' ? null :
                                        <div className="div_stamp">
                                            <img alt="img" src={ newData.stamp_2 } className="stamp_img" />
                                        </div>
                                    }
                                </div>  
                            </div>                          
                        </div>
                        { ShowDataPageClick() }
                        <div className="bg_data">
                            <img alt="img" src="./assets/PartsAndServices/bg_data_agricultural.png" className="bg_data_img" />
                        </div>
                        <div className="div_return">                            
                            {
                                prev == '-1' ?
                                <div className="return_stage stage_prev" onClick={ ()=>{ setPageDetails('list_details'); setIdDetails(nameLeftBtnClick_id) } }>
                                    <div className="return_arrow">
                                        <SvgArrow color="#FFDD00" />
                                    </div>
                                    <div className="return_name">
                                        { nameLeftBtnClick }
                                    </div>
                                </div> : 
                                <div className="return_stage stage_prev" onClick={ ()=>{ AltPrev(prev - 1, next - 1); setPageDetails(nameLeftBtnClick); setIdDetails(nameLeftBtnClick_id); } }>
                                    <div className="return_arrow">
                                        <SvgArrow color="#FFDD00" />
                                    </div>
                                    <div className="return_name" dangerouslySetInnerHTML={ { __html: nameLeftBtnClick ? nameLeftBtnClick.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                </div>
                            }
                            {
                                nameRightBtnClick == '' ? null :
                                <div className="return_stage stage_next" onClick={ ()=>{ AltPrev(prev + 1, next + 1); setPageDetails(nameRightBtnClick); setIdDetails(nameRightBtnClick_id); } }>
                                    <div className="return_name" dangerouslySetInnerHTML={ { __html: nameRightBtnClick ? nameRightBtnClick.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                    <div className="return_arrow">
                                        <SvgArrow color="#FFDD00" className="arrow_right" />
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            statusFile == false ? null :
                            <div className="div_parts">
                                <div className="list_div_parts">
                                    <div className="close_modal" onClick={ ()=>{ setStatusFile(false); setOpenFile(''); setOpenIndex(''); } }>
                                        <SvgClose className="icons" color="#FFDD00" />
                                    </div>
                                    {
                                        (openIndex - 1) >= 0 ? 
                                        <div className="prev_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenIndex(openIndex - 1) } }>
                                            <SvgArrowFile className="arrow_file" color="#FFDD00" />
                                        </div> : null
                                    }
                                    {
                                        (openIndex + 1) < newData.data[openFile].img.length ?
                                        <div className="next_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenIndex(openIndex + 1) } }>
                                            <SvgArrowFile className="arrow_file next_icon" color="#FFDD00" />
                                        </div> : null
                                    }
                                    <div className="show_details">
                                        <div className="mach_div_img">
                                            <img alt="img" src={ newData.data[openFile].img[openIndex][idioma] } className="img_div_parts" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                )
            }
        }
    }

    function RestartAnimation(){
        ShowDetailsClick()
        let count = 1;
        while(count <= 6){
            if(document.querySelector('.left_' + count)){
                document.querySelector('.left_' + count).classList.add('no_animation');
            }
            if(document.querySelector('.right_' + count)){
                document.querySelector('.right_' + count).classList.add('no_animation');
            }
            count++;
        }

        setTimeout(() => {
            let count = 1;
            while(count <= 6){
                if(document.querySelector('.left_' + count)){
                    document.querySelector('.left_' + count).classList.remove('no_animation');
                }
                if(document.querySelector('.right_' + count)){
                    document.querySelector('.right_' + count).classList.remove('no_animation');
                }
                count++;
            }
        }, 300);
    }

    function AltPrev(btn_prev, btn_next) {
        setPrev(btn_prev); 
        setNext(btn_next);

        if(document.querySelectorAll('.div_stamp')){
            const addClass_ = document.querySelectorAll('.div_stamp');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }

        if(document.querySelectorAll('.div_show_details_text')){
            const addClass_ = document.querySelectorAll('.div_show_details_text');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }        
        if(document.querySelectorAll('.div_show_details_contents')){
            const addClass_ = document.querySelectorAll('.div_show_details_contents');
            for (const addClass_alt of addClass_) {
                addClass_alt.style.display = 'none';
            }
        }        
        if(document.querySelectorAll('.show_img_1')){
            const addClass_ = document.querySelectorAll('.show_img_1');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }        
        if(document.querySelectorAll('.show_img_2')){
            const addClass_ = document.querySelectorAll('.show_img_2');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }        
        if(document.querySelectorAll('.show_img_3')){
            const addClass_ = document.querySelectorAll('.show_img_3');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }

        setTimeout(() => {
            if(document.querySelectorAll('.div_stamp')){
                const addClass_ = document.querySelectorAll('.div_stamp');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
            if(document.querySelectorAll('.div_show_details_text')){
                const addClass_ = document.querySelectorAll('.div_show_details_text');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }                
            if(document.querySelectorAll('.div_show_details_contents')){
                const addClass_ = document.querySelectorAll('.div_show_details_contents');
                for (const addClass_alt of addClass_) {
                    addClass_alt.style.display = 'flex';
                }
            }

            if(document.querySelectorAll('.show_img_1')){
                const addClass_ = document.querySelectorAll('.show_img_1');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
            if(document.querySelectorAll('.show_img_2')){
                const addClass_ = document.querySelectorAll('.show_img_2');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
            if(document.querySelectorAll('.show_img_3')){
                const addClass_ = document.querySelectorAll('.show_img_3');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
        }, 300);
    }

    function PlayVideo(id_video, id_div, btn_video) {
        const play_ = document.getElementById(id_video);
        if (play_.paused){
            play_.play();
            document.getElementById(btn_video).style.opacity = "0";
            document.getElementById(id_div).style.opacity = "0";
        }else {
            play_.pause();
            document.getElementById(btn_video).style.opacity = "1";
            document.getElementById(id_div).style.opacity = "1";
        }
    }

    function ShowDataPageClick(){
        let newData = [];
            if(nameSubMenu != 0){
                switch (nameSubMenu) {
                    case 'submenu_1':   
                            newData = subMenu_1.button.find(item => item.id == idDetails);                         
                        break;
                    case 'submenu_2':   
                            newData = subMenu_2.button.find(item => item.id == idDetails);                           
                        break;
                    case 'submenu_3':  
                            newData = subMenu_3.button.find(item => item.id == idDetails);                            
                        break;
                    case 'submenu_4': 
                            newData = subMenu_4.button.find(item => item.id == idDetails);                             
                        break;
                    case 'submenu_5': 
                            newData = subMenu_5.button.find(item => item.id == idDetails);   
                        break;
                }
            }else {
                newData = showData.button.find(item => item.id == idDetails);
            }
            
            let countDivImg = 0;
            if(newData.name[idioma] == ''){
                setIdDetails(0);
                setPageDetails('list_details');
            }

            let showText = '';
            if(newData.data.find(item => item.type == 'text')){
                showText = newData.data.find(item => item.type == 'text');  
            }

            let countImg = 0;
            if(newData.data.find(item => item.type == 'img')){
                let showCountImg = newData.data.filter(item => item.type == 'img');
                countImg = showCountImg[0].img.length;
            }

            let countVideo = 0;
            if(newData.data.find(item => item.type == 'video')){
                let showCountVideo = newData.data.filter(item => item.type == 'video');
                countVideo = showCountVideo.length;
            }
            let total = countImg + countVideo;

            return(
                <div className="div_show_details">
                    <div className="div_show_details_text">
                        <div className="div_show_text" dangerouslySetInnerHTML={ { __html: showText.text[idioma] ? showText.text[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                    </div>
                    <div className="div_show_details_contents">
                        {
                            newData.data.map((key, index)=>{
                                switch (key.type) {
                                    case 'video':
                                        return(
                                            <div className="div_show_details_div_video" key={ index }>
                                                <div id={ "play_" + index } className="play_video" onClick={ ()=>{ PlayVideo('open_video_' + index + '', 'play_' + index + '', 'btn_video_' + index + ''); setStatus(!status) } }>
                                                    <div id={ "btn_video_" + index } className="div_show_details_div_video_img_play">
                                                        <img alt="img" src="./assets/PartsAndServices/player.png" className="img_icon" />
                                                    </div> 
                                                    <img alt="img" src={ key.video_img[idioma] } className="div_show_details_div_video_img_" />
                                                </div>
                                                <div>  
                                                    <video id={ "open_video_" + index } width="100%" height="100%" controls={ false } loop={ true }>
                                                        <source src={ key.video[idioma] } type="video/mp4" />
                                                    </video>
                                                </div>
                                            </div>
                                        )

                                    case "img":
                                        return(
                                            <div className={ countVideo == 0 ? 
                                                total > 2 ? "div_show_details_div_img " : "div_show_details_div_img" : "div_show_details_div_img div_mult_video" } key={ index }>
                                                {
                                                    key.img.map((key_1, index_1)=>{
                                                        countDivImg = countDivImg + 1
                                                        return(
                                                            <div className={ 
                                                                total == 1 ? "div_show_details_show_img show_img_" + countDivImg : 
                                                                total == 2 ? "div_show_details_show_img two_show_img show_img_" + countDivImg : 
                                                                countVideo == 0 ? "div_show_details_show_img not_video_mult_show_img show_img_" + countDivImg : 
                                                                countImg > 2 ? "div_show_details_show_img mult_show_img_video show_img_" + countDivImg : "div_show_details_show_img mult_show_img show_img_" + countDivImg  } key={ index_1 }onClick={ ()=>{ setStatusFile(true); setOpenFile(index); setOpenIndex(index_1) } }>
                                                                <div className="search">
                                                                    <img alt="search" src="./assets/search.png" className="icon_search" />
                                                                </div> 
                                                                <img alt="img_" src={ key_1[idioma] } className="div_show_details_img" />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                }
                            })
                        }
                    </div>
                </div>
            )
    }

    return(
        <div className="MesaPartsAndServices_Agricultural">
            { ListDataClick() }
            {
                pageDetails != 'list_details' ? null : 
                <div className="div_example">
                    <img alt="img" src={ "./assets/cursor_" + idioma + ".png" } className="icone_button" />
                </div>
            }
        </div>
    )
}