import { useState, useEffect } from "react";

import Cookies from 'universal-cookie';

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import MesaUnimil from "components/MesaUnimil";
import MesaPartsAndServices from "components/MesaPartsAndServices";
import MesaUpgrades from "components/MesaUpgrades";
import MesaOperationCenter from "components/MesaOperationCenter";
import { SvgClose } from "components/Svg";
import MesaLiquid from "components/MesaLiquid";

export default function App() {

    const cookies = new Cookies();

    const nameUrl                         = window.location.href.split("#");
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ nameClickPage, setNameClickPage ] = useState('');

    const [ status, setStatus ] = useState(false);
    const [ textFixed, setTextFixed ] = useState({
        "text_1": {
            "pt_br": "Peças e serviços",
            "en": "parts_and_service"    
        },
        "text_2": {
            "pt_br": "Preparo",
            "en": "tillage"
        },
        "text_3": {
            "pt_br": "Plantio",
            "en": "planting"
        },
        "text_4": {
            "pt_br": "Applying",
            "en": "applying"
        },
        "text_5": {
            "pt_br": "Colheita grãos",
            "en": "grain_harvest"
        },
        "text_6": {
            "pt_br": "Colheita cana",
            "en": "sugarcane_harvest"
        },
        "text_7": {
            "pt_br": "Colheita algodão",
            "en": "cotton_forrage"
        },
        "text_8": {
            "pt_br": "Trator utilitário",
            "en": "utility_tractor"
        }
    });

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);
    }, []);

    useEffect(()=>{
        setCurrentPage(GetListPag('currentPage'));
    }, [currentPage]);

    useEffect(()=>{
        switch (nameUrl[1]) {
            case 'operationscenter': case 'unimil': case 'partsandservices': case 'upgrades': case 'liquid':
                    PageClick(nameUrl[1]);
                break;
        }
        setCurrentPage(GetListPag('currentPage'));
    }, [nameUrl]);

    function PageClick(value){
        SetListPag('currentPage', value);
    }
    
    let countClick = 0;
    function ResetPage(value){
        countClick = countClick + 1;
        if(countClick == 3){
            window.location.reload(true);
        }
    }

    function CurrentPageData(){
        switch (currentPage) {
            case 'index':
                return(
                    <div className="list_table">
                        <a href="#operationscenter" className="table" onClick={ ()=>{ PageClick('operationscenter') } }>
                            Operation center
                        </a>
                        <a href="#liquid" className="table" onClick={ ()=>{ PageClick('liquid') } }>
                            Petroplus
                        </a>
                        <a href="#unimil" className="table" onClick={ ()=>{ PageClick('unimil') } }>
                            Unimil
                        </a>
                        <div className="table" onClick={ ()=>{ setStatus(true); } }>
                            Peças e serviços
                        </div>
                        {
                            status == false ? null :
                            <div className="pop_up">
                                <div className="list_machine" style={ { width: "80%" } }>
                                    <div className="close_modal" onClick={ ()=>{ setStatus(false); } }>
                                        <SvgClose className="icons" color="#387C2B" />
                                    </div>
                                    <div className="div_opt_click">
                                        {
                                            Object.keys(textFixed).map((key, index)=>{
                                                return(
                                                    <a href={ "#partsandservices#" + textFixed[key]['en'] } className="show_opt_click" onClick={ ()=>{ setStatus(false); PageClick('partsandservices'); setNameClickPage(textFixed[key]['en']) } } key={ index }>
                                                        {
                                                            textFixed[key]['pt_br']
                                                        }
                                                    </a>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                );

            case "operationscenter":
                return <MesaOperationCenter PageClick={ PageClick } ResetPage={ ResetPage } />;

            case "liquid":
                return <MesaLiquid PageClick={ PageClick } ResetPage={ ResetPage } />;

            case "unimil":
                return <MesaUnimil PageClick={ PageClick } ResetPage={ ResetPage } />;

            case "partsandservices":
                return <MesaPartsAndServices PageClick={ PageClick } nameClickPage={ nameClickPage } ResetPage={ ResetPage } />;
        }
    } 

    return CurrentPageData();
}