import { useState, useEffect } from "react";

import './Upgrades.css';

import { SvgArrow, SvgArrowFile, SvgClose, SvgHome } from "components/Svg";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";

export default function MesaPartsAndServices_Upgrades(props){

    const [ nameUrl, setNameUrl ]         = useState(window.location.href.split("#"));
    const [ currentPage, setCurrentPage ] = useState('index');
    const [ pageDetails, setPageDetails ] = useState('list_details');
    const [ idDetails, setIdDetails ]     = useState(0);
    const [ idioma, setIdioma ]           = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ]       = useState(GetDataPage('upgrades'));

    const [ showData, setShowData ] = useState(dataPage[nameUrl[2]]);

    const [ textFixed, setTextFixed ] = useState({
        "text_1": {
            "pt_br": "Upgrades",
            "en": "Upgrades",
            "es": "Upgrades"       
        },
        "text_2": {
            "pt_br": "PREPARO",
            "en": "TILLAGE",
            "es": "PREPARACIÓN"
        },
        "text_3": {
            "pt_br": "PLANTIO",
            "en": "PLANTING",
            "es": "PLANTACIÓN"
        },
        "text_4": {
            "pt_br": "APPLYING",
            "en": "APPLYING",
            "es": "APPLYING"
        },
        "text_5": {
            "pt_br": "COLHEITA GRÃOS",
            "en": "GRAIN HARVEST",
            "es": "COSECHA DE GRANOS"
        },
        "text_6": {
            "pt_br": "COLHEITA CANA",
            "en": "SUGARCANE HARVEST",
            "es": "COSECHA DE CAÑA"
        },
        "text_7": {
            "pt_br": "COLHEITA ALGODÃO",
            "en": "COTTON & FORRAGE",
            "es": "ALGODÓN Y FORRAJE"
        },
        "text_8": {
            "pt_br": "TRATOR UTILITÁRIO",
            "en": "UTILITY TRACTOR",
            "es": "TRACTOR UTILITARIO"
        }
    });

    const [ next, setNext ]         = useState('');
    const [ prev, setPrev ]         = useState('');
    const [ namePage, setNamePage ] = useState(ShowNamePage());
    const [ typeClick, setTypeClick ] = useState('');

    const [ status, setStatus ]         = useState(false);    
    const [ openIndex, setOpenIndex ]   = useState('');
    const [ openFile, setOpenFile ]     = useState('');
    const [ statusFile, setStatusFile ] = useState(false);

    const [ statusSubMenu, setStatusSubMenu ] = useState(false);

    let countLeft  = 0;
    let countRight = 0;
    let countImg   = 0;

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('partsAndServices', setDataPage);
    }, []);

    useEffect(()=>{
        ListDataClick();
        setNamePage(ShowNamePage());
    }, [idioma]);

    function ShowNamePage(){
        switch (nameUrl[2]) {
            case "tillage":
                return textFixed.text_2[idioma];

            case "planting":
                return textFixed.text_3[idioma];

            case "applying":
                return textFixed.text_4[idioma];

            case "grain_harvest":
                return textFixed.text_5[idioma];

            case "sugarcane_harvest":
                return textFixed.text_6[idioma];

            case "cotton_forrage":
                return textFixed.text_7[idioma];

            case "utility_tractor":
                return textFixed.text_8[idioma];
        }
    }

    function ListDataClick(){
        return(
            <>
                <div className="div_topic">
                    <div className="icon_return">
                        <div className="return" onClick={ ()=>{ props.ClickPage('index') } }>
                            <SvgHome className="icon_home" color="#FFDD00" />
                        </div>
                        <div className="name_current_page">
                            <div className="show_name">/</div>
                            <div className="show_name" onClick={ ()=>{ setCurrentPage('index'); setPageDetails('list_details'); } }>
                                { showData.name[idioma] }
                            </div>
                            <div className="show_name">/</div>
                            <div className="show_name" onClick={ ()=>{ setCurrentPage('index'); setPageDetails('list_details'); } }>
                                Upgrades
                            </div>
                            {/* {
                                pageDetails == 'list_details' ? null :
                                <>
                                    <div className="show_name">/</div>
                                    <div className="show_name">{ pageDetails }</div>
                                </>
                            } */}
                        </div>
                    </div>
                </div>
                { ShowDetailsClick() }
            </>
        )
    }

    function OpenPopUP(type, id){
        if(type == 0){
            document.getElementById(id).classList.add('open_popup');
            document.getElementById(id).classList.remove('close_popup');
        }
        if(type == 1){
            document.getElementById(id).classList.remove('open_popup');
            document.getElementById(id).classList.add('close_popup');
        }
    }

    function ShowDetailsClick(){
        const buttonsLeft  = showData.button.filter(item =>item.position === 'left');
        const countButtonsLeft  = buttonsLeft.filter(item =>item.name[idioma] != '');
        countButtonsLeft.sort(function(a, b) {
            if(a.name[idioma] < b.name[idioma]) {
                return -1;
            } else {
                return true;
            }
        });

        const buttonsRight      = showData.button.filter(item =>item.position === 'right');
        const countButtonsRight = buttonsRight.filter(item =>item.name[idioma] != '');
        countButtonsRight.sort(function(a, b) {
            if(a.name[idioma] < b.name[idioma]) {
                return -1;
            } else {
                return true;
            }
        });

        if(pageDetails == 'list_details'){
            return(
                <>
                    <div className="div_name_page">
                        <div className="name_page">{ showData.name[idioma] }</div>
                    </div>
                    <div className="show_buttons">
                        <div className={ countButtonsLeft.length > 5 ? "div_buttons alt_gap" : "div_buttons" }>
                            {
                                countButtonsLeft.map((key, index)=>{
                                    countLeft = countLeft + 1;
                                    return(
                                        <div className={ countButtonsLeft.length > 5 ? "alt_width name_button border_left left_" + countLeft : "name_button border_left left_" + countLeft } key={ index } onClick={ ()=>{ setPageDetails(key.name[idioma]); setIdDetails(key.id); setPrev(index - 1); setNext(index + 1); setTypeClick('left') } }>
                                            <span className="space_name" dangerouslySetInnerHTML={ { __html: key.name[idioma] ? key.name[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={ countButtonsRight.length > 5 ? "div_buttons alt_gap" : "div_buttons" }>
                            {
                                countButtonsRight.map((key, index)=>{
                                    countRight = countRight + 1;
                                    if(key.name['en'] == "parts_and_services"){
                                        return(
                                            <div className={ countButtonsRight.length > 5 ? "alt_width name_button border_right right_" + countRight : "name_button border_right right_" + countRight } key={ index }>
                                                <span className="space_name" onClick={ ()=>{ OpenPopUP(0, "id_inf_" + key.id) } }>{ key.name[idioma] }</span>
                                                    
                                                <div id={ "id_inf_" + key.id } className="pop_up close_popup">
                                                    <div className="list_machine">
                                                        <div className="close_modal" onClick={ ()=>{ OpenPopUP(1, "id_inf_" + key.id) } }>
                                                            <SvgClose className="icons" color="#387C2B" />
                                                        </div>
                                                        <div className="div_button">
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }else {
                                        return(
                                            <div className={ countButtonsRight.length > 5 ? "alt_width name_button border_right right_" + countRight : "name_button border_right right_" + countRight } key={ index } onClick={ ()=>{ setPageDetails(key.name[idioma]); setIdDetails(key.id); setPrev(index - 1); setNext(index + 1); setTypeClick('right') } }>
                                                <span className="space_name" dangerouslySetInnerHTML={ { __html: key.name[idioma] ? key.name[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div className="div_img">
                        <img alt="img" src={ showData.img[idioma] } className="bg_img" />
                    </div>
                </>
            )
        }else {
            const newData = showData.button.find(item => item.id == idDetails);    
            let nameLeftBtnClick    = '';
            let nameLeftBtnClick_id = '';

            let nameRightBtnClick    = '';
            let nameRightBtnClick_id = '';
            if(typeClick == 'left'){
                if(prev == '-1'){
                    nameLeftBtnClick    = "Voltar";
                    nameLeftBtnClick_id = 0;
                }else {
                    nameLeftBtnClick    = countButtonsLeft[prev].name[idioma];
                    nameLeftBtnClick_id = countButtonsLeft[prev].id;
                }
                if(next >= countButtonsLeft.length){
                    const newDataRight = showData.button.filter(item =>item.position == 'right');
                    const newDataRightNext = newDataRight.filter(item =>item.name[idioma] != '');
                    if(newDataRightNext.length > 0){
                        setPrev(0);
                        setNext(1);
                        setTypeClick('right');
                    }else {
                        nameRightBtnClick    = '';
                        nameRightBtnClick_id = 0;
                    }
                }else {
                    nameRightBtnClick = countButtonsLeft[next].name[idioma];
                    nameRightBtnClick_id = countButtonsLeft[next].id;
                }

            }else if(typeClick == 'right'){
                if(prev == '-1'){
                    nameLeftBtnClick    = "Voltar";
                    nameLeftBtnClick_id = 0;
                }else {
                    nameLeftBtnClick    = countButtonsRight[prev].name[idioma];
                    nameLeftBtnClick_id = countButtonsRight[prev].id;
                }
                if(next >= countButtonsRight.length){
                    nameRightBtnClick = '';
                    nameRightBtnClick_id = 0;
                }else {
                    nameRightBtnClick    = countButtonsRight[next].name[idioma];
                    nameRightBtnClick_id = countButtonsRight[next].id;
                }
            }
            
            return(
                <>
                    <div className="name_page_click">
                        <div className="show_name_page_click">
                            <div>{ pageDetails }</div>
                            <div className={ newData.stamp_1 != '' && newData.stamp_2 != '' ? "stamp" : "stamp stamp_one" }>
                                {
                                    newData.stamp_1 == '' ? null :
                                    <div className="div_stamp">
                                        <img alt="img" src={ newData.stamp_1 } className="stamp_img" />
                                    </div>
                                }
                                {
                                    newData.stamp_2 == '' ? null :
                                    <div className="div_stamp">
                                        <img alt="img" src={ newData.stamp_2 } className="stamp_img" />
                                    </div>
                                }
                            </div>  
                        </div>                          
                    </div>
                    { ShowDataPageClick() }
                    <div className="bg_data">
                        <img alt="img" src="./assets/PartsAndServices/bg_data_agricultural.png" className="bg_data_img" />
                    </div>
                    <div className="div_return">                            
                        {
                            prev == '-1' ?
                            <div className="return_stage stage_prev" onClick={ ()=>{ setPageDetails('list_details'); setIdDetails(nameLeftBtnClick_id) } }>
                                <div className="return_arrow">
                                    <SvgArrow color="#FFDD00" />
                                </div>
                                <div className="return_name">
                                    { nameLeftBtnClick }
                                </div>
                            </div> : 
                            <div className="return_stage stage_prev" onClick={ ()=>{ AltPrev(prev - 1, next - 1); setPageDetails(nameLeftBtnClick); setIdDetails(nameLeftBtnClick_id) } }>
                                <div className="return_arrow">
                                    <SvgArrow color="#FFDD00" />
                                </div>
                                <div className="return_name">
                                    { nameLeftBtnClick }
                                </div>
                            </div>
                        }
                        {
                            nameRightBtnClick == '' ? null :
                            <div className="return_stage stage_next" onClick={ ()=>{ AltPrev(prev + 1, next + 1); setPageDetails(nameRightBtnClick); setIdDetails(nameRightBtnClick_id) } }>
                                <div className="return_name">
                                    { nameRightBtnClick }
                                </div>
                                <div className="return_arrow">
                                    <SvgArrow color="#FFDD00" className="arrow_right" />
                                </div>
                            </div>
                        }
                    </div>
                    {
                        statusFile == false ? null :
                        <div className="div_parts">
                            <div className="list_div_parts">
                                <div className="close_modal" onClick={ ()=>{ setStatusFile(false); setOpenFile(''); setOpenIndex(''); } }>
                                    <SvgClose className="icons" color="#FFDD00" />
                                </div>
                                {
                                    (openIndex - 1) >= 0 ? 
                                    <div className="prev_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenIndex(openIndex - 1) } }>
                                        <SvgArrowFile className="arrow_file" color="#FFDD00" />
                                    </div> : null
                                }
                                {
                                    (openIndex + 1) < newData.data[openFile].img.length ?
                                    <div className="next_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenIndex(openIndex + 1) } }>
                                        <SvgArrowFile className="arrow_file next_icon" color="#FFDD00" />
                                    </div> : null
                                }
                                <div className="show_details">
                                    <div className="mach_div_img">
                                        <img alt="img" src={ newData.data[openFile].img[openIndex][idioma] } className="img_div_parts" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            )
        }
    }

    function AltPrev(btn_prev, btn_next) {
        setPrev(btn_prev); 
        setNext(btn_next);

        if(document.querySelectorAll('.div_stamp')){
            const addClass_ = document.querySelectorAll('.div_stamp');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }

        if(document.querySelectorAll('.div_show_details_text')){
            const addClass_ = document.querySelectorAll('.div_show_details_text');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }        
        if(document.querySelectorAll('.div_show_details_contents')){
            const addClass_ = document.querySelectorAll('.div_show_details_contents');
            for (const addClass_alt of addClass_) {
                addClass_alt.style.display = 'none';
            }
        }        
        if(document.querySelectorAll('.show_img_1')){
            const addClass_ = document.querySelectorAll('.show_img_1');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }        
        if(document.querySelectorAll('.show_img_2')){
            const addClass_ = document.querySelectorAll('.show_img_2');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }        
        if(document.querySelectorAll('.show_img_3')){
            const addClass_ = document.querySelectorAll('.show_img_3');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }

        setTimeout(() => {
            if(document.querySelectorAll('.div_stamp')){
                const addClass_ = document.querySelectorAll('.div_stamp');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
            if(document.querySelectorAll('.div_show_details_text')){
                const addClass_ = document.querySelectorAll('.div_show_details_text');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }                
            if(document.querySelectorAll('.div_show_details_contents')){
                const addClass_ = document.querySelectorAll('.div_show_details_contents');
                for (const addClass_alt of addClass_) {
                    addClass_alt.style.display = 'flex';
                }
            }

            if(document.querySelectorAll('.show_img_1')){
                const addClass_ = document.querySelectorAll('.show_img_1');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
            if(document.querySelectorAll('.show_img_2')){
                const addClass_ = document.querySelectorAll('.show_img_2');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
            if(document.querySelectorAll('.show_img_3')){
                const addClass_ = document.querySelectorAll('.show_img_3');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
        }, 300);
    }

    function PlayVideo(id_video, id_div, btn_video) {
        const play_ = document.getElementById(id_video);
        if (play_.paused){
            play_.play();
            document.getElementById(btn_video).style.opacity = "0";
            document.getElementById(id_div).style.opacity = "0";
        }else {
            play_.pause();
            document.getElementById(btn_video).style.opacity = "1";
            document.getElementById(id_div).style.opacity = "1";
        }
    }

    function ShowDataPageClick(){
        const newData = showData.button.find(item => item.id == idDetails); 
        let countDivImg = 0;
        if(newData.name[idioma] == ''){
            setIdDetails(0);
            setPageDetails('list_details');
        }

        let showText = '';
        if(newData.data.find(item => item.type == 'text')){
            showText = newData.data.find(item => item.type == 'text');  
        }

        let countImg = 0;
        if(newData.data.find(item => item.type == 'img')){
            let showCountImg = newData.data.filter(item => item.type == 'img');
            countImg = showCountImg[0].img.length;
        }

        let countVideo = 0;
        if(newData.data.find(item => item.type == 'video')){
            let showCountVideo = newData.data.filter(item => item.type == 'video');
            countVideo = showCountVideo.length;
        }
        let total = countImg + countVideo;

        return(
            <div className="div_show_details">
                <div className="div_show_details_text">
                    <div className="div_show_text" dangerouslySetInnerHTML={ { __html: showText.text[idioma] ? showText.text[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                </div>
                <div className="div_show_details_contents">
                    {
                        newData.data.map((key, index)=>{
                            switch (key.type) {
                                case 'video':
                                    return(
                                        <div className="div_show_details_div_video" key={ index }>
                                            <div id={ "play_" + index } className="play_video" onClick={ ()=>{ PlayVideo('open_video_' + index + '', 'play_' + index + '', 'btn_video_' + index + ''); setStatus(!status) } }>
                                                <div id={ "btn_video_" + index } className="div_show_details_div_video_img_play">
                                                    <img alt="img" src="./assets/PartsAndServices/player.png" className="img_icon" />
                                                </div> 
                                                <img alt="img" src={ key.video_img[idioma] } className="div_show_details_div_video_img_" />
                                            </div>
                                            <div>  
                                                <video id={ "open_video_" + index } width="100%" height="100%" controls={ false } loop={ true }>
                                                    <source src={ key.video[idioma] } type="video/mp4" />
                                                </video>
                                            </div>
                                        </div>
                                    )

                                case "img":
                                    return(
                                        <div className={ countVideo == 0 ? 
                                            total > 2 ? "div_show_details_div_img div_show_details_not_video" : "div_show_details_div_img" : "div_show_details_div_img div_mult_video" } key={ index }>
                                            {
                                                key.img.map((key_1, index_1)=>{
                                                    countDivImg = countDivImg + 1
                                                    return(
                                                        <div className={ 
                                                            total == 1 ? "div_show_details_show_img show_img_" + countDivImg : 
                                                            total == 2 ? "div_show_details_show_img two_show_img show_img_" + countDivImg : 
                                                            countVideo == 0 ? "div_show_details_show_img not_video_mult_show_img show_img_" + countDivImg : 
                                                            countImg > 2 ? "div_show_details_show_img mult_show_img_video show_img_" + countDivImg : "div_show_details_show_img mult_show_img show_img_" + countDivImg  } key={ index_1 }onClick={ ()=>{ setStatusFile(true); setOpenFile(index); setOpenIndex(index_1) } }>
                                                            <div className="search">
                                                                <img alt="search" src="./assets/search.png" className="icon_search" />
                                                            </div> 
                                                            <img alt="img_" src={ key_1[idioma] } className="div_show_details_img" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                            }
                        })
                    }
                </div>
            </div>
        )
    }
    
    return(
        <div className="MesaPartsAndServices_Upgrades">
            { ListDataClick() }
            {
                pageDetails != 'list_details' ? null : 
                <div className="div_example">
                    <img alt="img" src={ "./assets/cursor_" + idioma + ".png" } className="icone_button" />
                </div>
            }
        </div>
    )
}