import { useState, useEffect } from "react";

import './ExpandedPortfolio.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import ExpandedPortfolio_PtBr from "./PtBr";
import ExpandedPortfolio_Es from "./Es";
import ExpandedPortfolio_En from "./En";

export default function MesaPartsAndServices_ExpandedPortfolio(props){

    const [ showData, setShowData ] = useState(false);
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ page, setPage ]         = useState('index');

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);

        CountShowData();
        TypePage();
    }, []); 

    useEffect(()=>{
        CountShowData();
        TypePage();
    }, [page]);
    
    function CountShowData(){
        let count = 0;
        const countData = setInterval(() => {
            if(count == 1){
                setShowData(true); 
                clearInterval(countData);
            }
            count++; 
        }, 300);
    }
    
    function ClickPage(value){
        setPage(value);
    }

    function TypePage(){
        switch (idioma) {
            case 'pt_br':
                return <ExpandedPortfolio_PtBr ClickPage={ ClickPage } />;
            
            case 'es':
                return <ExpandedPortfolio_Es ClickPage={ ClickPage } />;
            
            case 'en':
                return <ExpandedPortfolio_En ClickPage={ ClickPage } />;
        }
    }

    return(
        <div className="MesaPartsAndServices_ExpandedPortfolio">
            <div className="div_return_index" onClick={ ()=>{ props.ClickPage('index') } }>
                <img alt="return" src="./assets/bt_menu_pt.png" className="show_img_return" />
            </div>
            <div className="reset_page" onClick={ ()=>{ props.ResetPage('index') } } />
            <div className={ showData == true ? "div_data show_data" : "div_data" }>
                {
                    TypePage()
                }
            </div>
            <div className="div_bg">
                <img alt="img" src="./assets/texture.png" className="texture" />
            </div>
        </div>
    )
}