import { useState, useEffect } from "react";

import './Es.css';

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function ExpandedPortfolio_Es(props){

    const [ nameUrl, setNameUrl ]   = useState(window.location.href.split("#"));
    const [ page, setPage ]         = useState('index');
    const [ dataPage, setDataPage ] = useState(GetDataPage('expanded_portfolio'));

    const [ showData, setShowData ] = useState(InitialData(0));
    const [ listLogo, setListLogo ] = useState(InitialData(1));

    useEffect(()=>{
        RegisterDataPage('expanded_portfolio', setDataPage);
    }, []); 

    useEffect(()=>{
        ShowCurrentData();
    }, [page]);

    function InitialData(type){
        if(type == 0){
            if(nameUrl[2] == 'utility_tractor'){
                const typeData = dataPage.filter(item => item.origin_ == 'utility_tractor'); 
                const newData  = typeData.filter(item => item.idioma == 'es');
                return newData;
                
            }else {
                const typeData = dataPage.filter(item => item.origin_ == 'all'); 
                const newData  = typeData.filter(item => item.idioma == 'es');
                return newData;
            } 
        }else {
            const typeData  = dataPage.filter(item => item.origin_ == 'all'); 
            const newData   = typeData.filter(item => item.idioma == 'es');
            const portFolio = newData.filter(item => item.id == 6);
            return portFolio[0].contents[0].gallery;
        }   
    }

    function ShowCurrentData(){
        const infPage  = showData.find(item => item.id == page); 
        switch (page) {
            case "index":
                return(
                    <div className="div_show_data">                    
                        <div className="bg_white show_logotipos">
                            {
                                listLogo.map((key, index)=>{
                                    return(
                                        <div className="show_data_contents div_logo" key={ index }>aaa  
                                            <img alt="img" src={ key.file } className="logotipo" />
                                        </div>
                                    )
                                })
                            }
                        </div> 
                        <div className="bg_blue">
                            <div className="show_data_contents">
                                <div className="list_buttons">
                                    {
                                        showData.map((key, index)=>{
                                            if(key.id != 9){
                                                if(key.name != 'Catálogo'){
                                                    return(
                                                        <div className="btn_about" key={ index } onClick={ ()=>{ setPage(key.id) } }>
                                                            { key.name }
                                                        </div>
                                                    )
                                                }
                                            }
                                        })
                                    }
                                </div>
                                {
                                    nameUrl[2] == 'utility_tractor' ? null :
                                    showData.map((key, index)=>{
                                        if(key.id == 4){
                                            return(
                                                <div className="machine" onClick={ ()=>{ setPage(key.id) } } key={ index }>
                                                    <div className="div_data_machine">
                                                        <div className="title_machine">{ key.name }</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                            <div className="div_example">
                                <img alt="img" src={ "./assets/cursor_es.png" } className="icone_button" />
                            </div>
                        </div>   
                    </div>
                )

            case 5: case 19:
                return(
                    <div className="page_1">
                        <div className="div_topic">
                            <div className="div_home" onClick={ ()=>{ setPage('index'); } }>
                                <img alt="img" src="./assets/home.png" className="img_home" />
                            </div>
                            <div className="div_name_page">
                                <div className="subtitle_page"></div>
                                <div className="name_page">{ infPage.name }</div>
                            </div>
                        </div>
                        <div className="div_img_page">
                            <div className="div_img_">
                                <img alt="img" src={ infPage.contents[0].img } className="show_img" />
                            </div>
                            <div className="div_img_">
                                <img alt="img" src={ infPage.contents[0].img_bg } className="show_img" />
                            </div>
                        </div>
                        <div className="div_bg_color" />
                    </div>
                )

            case 6: case 20:
                return(
                    <div className="page_2">
                        <div className="div_topic">
                            <div className="div_home" onClick={ ()=>{ setPage('index'); } }>
                                <img alt="img" src="./assets/home.png" className="img_home" />
                            </div>
                            <div className="div_name_page">
                                <div className="subtitle_page"></div>
                                <div className="name_page">{ infPage.name }</div>
                            </div>
                        </div>
                        <div className="div_data_page">                            
                            <div className="pag_text" dangerouslySetInnerHTML={ { __html: infPage.contents[0].text != '' ? infPage.contents[0].text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                            <div className="div_img_">
                                {
                                    listLogo.map((key, index)=>{
                                        return(
                                            <div className="show_logo" key={ index }>
                                                <div className="logo">
                                                    <img alt="img" src={ key.file } className="logotipo" />
                                                </div>
                                                <div className="logo_title">{ key.name }</div>
                                                <div className="logo_img_example">
                                                    <img alt="img" src={ key.img_example } className="img_example" />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                )

            case 7: case 21:
                return(
                    <div className="page_3">
                        <div className="div_topic">
                            <div className="div_home" onClick={ ()=>{ setPage('index'); } }>
                                <img alt="img" src="./assets/home.png" className="img_home" />
                            </div>
                            <div className="div_name_page">
                                <div className="subtitle_page"></div>
                                <div className="name_page">{ infPage.name }</div>
                            </div>
                        </div>
                        <div className="div_show_text">
                            <div className="div_data_page">
                                <div className="page_title">{ infPage.contents[0].title }</div>
                                <div className="page_text" dangerouslySetInnerHTML={ { __html: infPage.contents[0].text != '' ? infPage.contents[0].text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                            </div>
                            <div className="div_data_page">
                                <div className="page_title">{ infPage.contents[0].title_1 }</div>
                                <div className="page_text" dangerouslySetInnerHTML={ { __html: infPage.contents[0].text_1 != '' ? infPage.contents[0].text_1.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                            </div>
                        </div>
                    </div>
                )

            case 8:
                return(
                    <div className="page_4">
                        <div className="div_topic">
                            <div className="div_home" onClick={ ()=>{ setPage('index'); } }>
                                <img alt="img" src="./assets/home.png" className="img_home" />
                            </div>
                            <div className="div_name_page">
                                <div className="subtitle_page"></div>
                                <div className="name_page">{ infPage.name }</div>
                            </div>
                        </div>
                        <div className="div_data_page">                            
                            <div className="div_img_">
                                {
                                    infPage.contents[0]['gallery'].map((key, index)=>{
                                        return(
                                            <div className="show_logo" key={ index }>
                                                <div className="logo">
                                                    <img alt="img" src={ key.file } className="logotipo" />
                                                </div>
                                                <div className="logo_title">{ key.name }</div>
                                                <div className="logo_img_example">
                                                    <img alt="img" src={ key.img_example } className="img_example" />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                )

            case 9:
                return(
                    <div className="page_5">
                        <div className="div_topic">
                            <div className="div_home" onClick={ ()=>{ setPage('index'); } }>
                                <img alt="img" src="./assets/home.png" className="img_home" />
                            </div>
                            <div className="div_name_page">
                                <div className="subtitle_page"></div>
                                <div className="name_page">{ infPage.name }</div>
                            </div>
                        </div>
                        <div className="div_show_text">
                            <div className="div_data_page">
                                <div className="page_text" dangerouslySetInnerHTML={ { __html: infPage.contents[0].text != '' ? infPage.contents[0].text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                            </div>
                            <div className="div_data_page">
                                <div className="page_text" dangerouslySetInnerHTML={ { __html: infPage.contents[0].text_1 != '' ? infPage.contents[0].text_1.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                            </div>
                        </div>
                    </div>
                )
        }
    }

    return(
        <div className="ExpandedPortfolio_Es">
            { ShowCurrentData() }
        </div>
    )
}